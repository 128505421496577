import { RowPatternEnum } from './enums/row-pattern.enum';
import { Style } from './interfaces/style.interface';
import { Column } from './column.model';

export class Row {
  constructor(
    public id: string,
    public sort: number,
    public pattern: RowPatternEnum,
    public children: Column[],
    public style: Style,
  ) {}
}
