import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as LayoutActions from '@core/store/actions';
import { Menu } from '@core/models';
import { LayoutService } from '@core/services';

@Injectable()
export class LayoutEffects {
  public loadMenu$: any = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.loadMenu),
    switchMap(() =>
      this.layoutService.loadMenu().pipe(
        map((response: Menu[]) => LayoutActions.loadMenuSuccess({ menu: response })),
        catchError((error: string) => of(LayoutActions.loadMenuFail({ error }))),
      ),
    ),
  ));

  public loadLinks$: any = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.loadLinks),
    switchMap(() =>
      this.layoutService.loadLinks().pipe(
        map((response: Menu[]) => LayoutActions.loadLinksSuccess({ links: response })),
        catchError((error: string) => of(LayoutActions.loadLinksFail({ error }))),
      ),
    ),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly layoutService: LayoutService,
  ) { }
}
