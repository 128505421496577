import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ClesdefaState, getClesdefaState, PageState } from '@core/store/reducers';
import { Page } from '@core/models';

export const getPageState: MemoizedSelector<unknown, PageState, DefaultProjectorFn<PageState>> = createSelector(
  getClesdefaState,
  (state: ClesdefaState): PageState => !!state && state.page,
);

export const isPageLoading: MemoizedSelector<PageState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getPageState,
  (state: PageState): boolean => !!state && state.loading,
);

export const isPageLoaded: MemoizedSelector<PageState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getPageState,
  (state: PageState): boolean => !!state && state.loaded,
);

export const pageError: MemoizedSelector<PageState, string, DefaultProjectorFn<string>> = createSelector(
  getPageState,
  (state: PageState): string => !!state && state.error,
);

export const page: MemoizedSelector<PageState, Page, DefaultProjectorFn<Page>> = createSelector(
  getPageState,
  (state: PageState): Page => !!state && state.data,
);
