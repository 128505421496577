import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { RecaptchaService } from '@shared/services';
import { Form } from '@core/models';

const EMAIL: string = 'afavier';
const DOMAIN: string = 'clesdefa.com';

const SUBJECTS: string[] = [
  'Coaching individuel',
  'Animation de codéveloppement professionnel',
  'Formation dans le domaine éducatif',
  'Consultation dans le domaine éducatif',
  'Autre',
];

@Component({
  selector: 'shared-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnChanges, OnDestroy {
  @Input() public displayCancelButton: boolean = true;
  @Input() public displayTitle: boolean = true;
  @Input() public isSent: boolean = false;
  @Input() public isSending: boolean = false;
  @Input() public isLocked: boolean = false;
  @Input() public hasError: boolean = false;

  @Output() public readonly valid: EventEmitter<Form> = new EventEmitter<Form>();
  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  public email: string = EMAIL;
  public domain: string = DOMAIN;
  public subjects: string[] = SUBJECTS;
  public submitted: boolean = false;
  public contactForm: FormGroup;

  public get formControls(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  public get requiredClass(): { [key: string]: boolean } {
    return {
      form__required: true,
    };
  }

  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly recaptchaService: RecaptchaService,
  ) {
    this.buildForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLocked) {
      this.toggleDisabled(changes.isLocked.currentValue);
    }
  }

  public onCancel(): void {
    this.submitted = false;
    this.cancel.emit();
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    this.recaptchaService.getToken('contact')
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((token: string) => {
        this.contactForm.get('token').setValue(token);
        this.valid.emit(this.contactForm.value);
      });
  }

  public trackBySubject(idx: number, subject: string): string {
    return subject;
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next(false);
    this.unsubscribeAll.complete();
  }

  private buildForm(): void {
    this.contactForm = this.formBuilder.group({
      email: [{ value: null, disabled: this.isLocked }, [Validators.required, Validators.email]],
      subject: [{ value: null, disabled: this.isLocked }, Validators.required],
      name: [{ value: null, disabled: this.isLocked }, Validators.required],
      phone: [{ value: null, disabled: this.isLocked }, Validators.required],
      body: [{ value: null, disabled: this.isLocked }, Validators.required],
      gdrp: [{ value: null, disabled: this.isLocked }, Validators.requiredTrue],
      token: [null],
    });
  }

  private toggleDisabled(disabled: boolean): void {
    const controls: { [key: string]: AbstractControl<any> } = this.contactForm?.controls || {};
    const fields: string[] = Object.keys(controls);

    return disabled
      ? fields.forEach(key => this.contactForm.get(key)?.disable())
      : fields.forEach(key => this.contactForm.get(key)?.enable());
  }
}
