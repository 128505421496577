import { FieldTypeEnum } from './enums/field-type.enum';

export class Field {
  constructor(
    public key: string,
    public name: string,
    public type: FieldTypeEnum,
    public sort: number,
    public displayed: boolean = true,
    public required: boolean = true,
  ) {}
}
