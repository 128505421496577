import { Component, Input } from '@angular/core';
import { ContentComponent } from '@shared/components/contents/content.component';
import { Style, Widget, WidgetContent } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultBackgroundColor: ColorsEnum = ColorsEnum.ORANGE;
  private defaultPadding: string = '30px';
  private defaultWidth: string = '290px';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { text: string, path: string } {
    return this.contentWidget?.content as { text: string, path: string };
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get styleContainer(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
    };
  }

  public get styleProfile(): { [key: string]: string } {
    return {
      width: this.addInSize(this.contentStyle.width, 75) || this.addInSize(this.defaultWidth, 75),
      height: this.addInSize(this.contentStyle.width, 10) || this.addInSize(this.defaultWidth, 10),
    };
  }

  public get stylePicture(): { [key: string]: string } {
    return {
      width: this.contentStyle.width || this.defaultWidth,
      height: this.contentStyle.width || this.defaultWidth,
    };
  }

  public get styleForm(): { [key: string]: string } {
    return {
      color: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
    };
  }

  private addInSize(width: string, toAdd: number = 60): string {
    if (!width) {
      return;
    }

    const widthInNumber: number = parseFloat(width.replace('px', ''));

    return `${widthInNumber + toAdd}px`;
  }
}
