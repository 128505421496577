import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanAccessGuard implements CanActivate {
  constructor(
    private readonly router: Router,
  ) {}

  public canActivate(
  // next: ActivatedRouteSnapshot,
  // state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return environment.production
      ? this.router.navigate(['/coming-soon'])
      : true;
  }
}
