<app-layout>
  <div class="app-layout__loading" *ngIf="(pageLoading$ | async); else page">
    <shared-loader [route]="route"></shared-loader>
  </div>

  <ng-template #page>
    <ng-container *ngIf="(page$ | async) as page">
      <ng-container *ngIf="hasRealId(page); else dynamic">
        <shared-page [pageId]="page.realId"></shared-page>
      </ng-container>

      <ng-template #dynamic>
        <shared-row *ngFor="let row of page.content; trackBy:trackById" [row]="row"></shared-row>
      </ng-template>
    </ng-container>
  </ng-template>
</app-layout>
