<div class="app-login">
  <div class="app-login__header">
    <shared-clesdefa-logo class="header__logo"></shared-clesdefa-logo>
  </div>

  <div class="app-login__error" *ngIf="(error$ | async)">
    {{ 'LOGIN-FORM.ERROR' | translate }}
  </div>

  <div class="app-login__content">
    <form class="content__form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="form__input" appearance="outline">
        <input formControlName="email" type="email" class="input__value"
          [readonly]="(login$ | async)"
          [placeholder]="'LOGIN-FORM.EMAIL.LABEL' | translate" matInput>

        <mat-error *ngIf="formControls.email.errors">
          {{ 'LOGIN-FORM.EMAIL.ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form__input" appearance="outline">
        <input formControlName="password" type="password" class="input__value"
          [readonly]="(login$ | async)"
          [placeholder]="'LOGIN-FORM.PASSWORD.LABEL' | translate" matInput>

        <mat-error *ngIf="formControls.password.errors">
          {{ 'LOGIN-FORM.PASSWORD.ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="form__actions">
        <button mat-button type="button" [disabled]="(login$ | async)" (click)="onBack()">
          {{ 'LOGIN-FORM.ACTIONS.CANCEL' | translate }}
        </button>
        <button mat-raised-button color="primary" [disabled]="(login$ | async)">
          <mat-spinner *ngIf="(login$ | async); else text" [diameter]="25"></mat-spinner>
          <ng-template #text>
            {{ 'LOGIN-FORM.ACTIONS.SUBMIT' | translate }}
          </ng-template>
        </button>
      </div>
    </form>
  </div>
</div>
