import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Form } from '@core/models';
import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private readonly apiContactUrl: string = `${ this.configService.apiUrl }/contact`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public send(form: Form): Observable<void> {
    return this.httpService
      .perform<Form, void>(HttpMethod.post, `${this.apiContactUrl}`, form);
  }
}
