import { Menu } from '@core/models';

export const menuHomeMock: Menu = new Menu('home', 'Accueil', '/');

export const menuExpertiseCicMock: Menu = new Menu('cic', 'Coaching individuel', '/expertise/cic');
export const menuExpertiseAcpMock: Menu = new Menu('acp', 'Animation de codéveloppement professionnel', '/expertise/acp');
export const menuExpertiseFdeMock: Menu = new Menu('fde', 'Formation dans le domaine éducatif', '/expertise/fde');
export const menuExpertiseCdeMock: Menu = new Menu('cde', 'Consultation dans le domaine éducatif', '/expertise/cde');
export const menuExpertiseMock: Menu = new Menu('expertise', 'Mes propositions', '/expertise', true, [
  menuExpertiseCicMock,
  menuExpertiseAcpMock,
  menuExpertiseFdeMock,
  menuExpertiseCdeMock,
]);

// TODO: blog
export const menuBlogMock: Menu = new Menu('blog', 'Blog', '/blog', false, undefined, true);
export const menuAboutMock: Menu = new Menu('about-me', 'À propos', '/about-me');
export const menuContactMock: Menu = new Menu('contact', 'Contact', '/contact');

export const menuMock: Menu[] = [
  menuHomeMock,
  menuExpertiseMock,
  menuBlogMock,
  menuAboutMock,
  menuContactMock,
];

export const linkLegalNoticeMock: Menu = new Menu('legalNotice', 'Mentions légales', '/legal-notice');
export const linkPrivacyPolicyMock: Menu = new Menu('privacyPolicy', 'Politique de confidentialité', '/privacy-policy');

export const linksMock: Menu[] = [
  linkLegalNoticeMock,
  linkPrivacyPolicyMock,
];
