<div class="coming-soon">
  <div class="coming-soon__content">
    <shared-clesdefa-logo class="content__logo"></shared-clesdefa-logo>

    <div class="content__text">
      <div class="text__title">
        {{ 'COMING-SOON.TITLE' | translate }}
      </div>

      <div class="text__content">
        {{ 'COMING-SOON.CONTENT' | translate }}
      </div>
    </div>

    <div class="content__actions">
      <button mat-raised-button (click)="onContact()">
        <span>{{ 'COMING-SOON.BUTTON' | translate }}</span>
        <shared-svg-icon class="icon" icon="button-arrow"></shared-svg-icon>
      </button>
    </div>
  </div>
</div>
