export enum RowPatternEnum {
  SIMPLE = '1',
  SIMPLE_SIMPLE = '1-1',
  SIMPLE_DOUBLE = '1-2',
  DOUBLE_SIMPLE = '2-1',
  SIMPLE_SIMPLE_SIMPLE = '1-1-1',
  SIMPLE_DOUBLE_SIMPLE = '1-2-1',
  DOUBLE_SIMPLE_SIMPLE = '2-1-1',
  SIMPLE_SIMPLE_DOUBLE = '1-1-2',
  SIMPLE_SIMPLE_SIMPLE_SIMPLE = '1-1-1-1',
  SIMPLE_GROW = '0-1',
}
