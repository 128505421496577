import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SkeletonLoaderComponent } from './components/skeleton-loader.component';
import { SKELETON_LOADER_CONFIG, SkeletonLoaderConfig } from './skeleton-loader.types';

@NgModule({
  declarations: [SkeletonLoaderComponent],
  imports: [CommonModule],
  exports: [SkeletonLoaderComponent],
})
export class SkeletonLoaderModule {
  public static forRoot(config?: Partial<SkeletonLoaderConfig>): ModuleWithProviders<SkeletonLoaderModule> {
    return {
      ngModule: SkeletonLoaderModule,
      providers: [{ provide: SKELETON_LOADER_CONFIG, useValue: config }],
    };
  }
}
