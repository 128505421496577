export enum WidgetEnum {
  TEXT,
  IMAGE,
  BUTTON,
  SEPARATOR,
  CIRCLE_BLOCK,
  QUOTES,
  TESTIMONIES,
  CONTACT,
  CONTACT_FORM,
  ARTICLES,
  PROFILE_PICTURE,
  CARD,
}
