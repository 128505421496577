import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(
    private readonly reCaptchaService: ReCaptchaV3Service,
  ) { }

  public getToken(operationName: string): Observable<string> {
    return this.reCaptchaService.execute(operationName).pipe(
      take(1),
    );
  }
}
