// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: { [key: string]: any } = {
  production: false,
  enableTracing: true,
  hmr: false,
  i18n: {
    locales: ['fr-FR', 'en-US'],
    languages: ['fr', 'en'],
    fallback: 'en-US',
    defaultLocale: 'fr-FR',
    storageKey: 'cdf_user_lang',
    header: 'X-Lang',
  },
  authentication: {
    jwtTokenKey: 'cdf_jwt_token',
    jwtExpireAtKey: 'cdf_jwt_expire_at',
  },
  api: {
    baseUrl: 'https://api-dev.clesdefa.com',
    origin: '*',
  },
  recaptcha: {
    siteKey: '6LejJnkaAAAAAGo-lFPt-YLsBMHzl2DA07v-LN3L',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
