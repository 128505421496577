import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as PageActions from '@core/store/actions';
import { Page } from '@core/models';

export interface PageState {
  loading: boolean;
  loaded: boolean;
  error: string;
  data: Page;
}

export const initialPageState: PageState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
};

const reducer: ActionReducer<PageState, Action> = createReducer(
  initialPageState,
  on(PageActions.loadPage, (state: PageState) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(PageActions.loadPageSuccess, (state: PageState, { page }: { page: Page }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: page,
  })),
  on(PageActions.loadPageFail, (state: PageState, { error }: { error: string }) => ({
    ...state,
    loading: false,
    loaded: false,
    error,
  })),
);

export function pageReducer(state: PageState | undefined, action: Action): PageState {
  return reducer(state, action);
}
