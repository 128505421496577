import { Component, Input } from '@angular/core';

import { Menu } from '@core/models';

@Component({
  selector: 'shared-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() public menu: Menu[];
  @Input() public links: Menu[];
}
