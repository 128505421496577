import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { AuthState, ClesdefaState, getClesdefaState, LoginState, LogoutState } from '@core/store/reducers';

export const getAuthState: MemoizedSelector<unknown, AuthState, DefaultProjectorFn<AuthState>> = createSelector(
  getClesdefaState,
  (state: ClesdefaState): AuthState => !!state && state.auth,
);

export const loginState: MemoizedSelector<unknown, LoginState, DefaultProjectorFn<LoginState>> = createSelector(
  getAuthState,
  (state: AuthState): LoginState => !!state && state.login,
);

export const logoutState: MemoizedSelector<unknown, LogoutState, DefaultProjectorFn<LogoutState>> = createSelector(
  getAuthState,
  (state: AuthState): LogoutState => !!state && state.logout,
);

export const isLoggingIn: MemoizedSelector<LoginState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  loginState,
  (state: LoginState): boolean => !!state && state.loading,
);

export const isLogged: MemoizedSelector<LoginState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  loginState,
  (state: LoginState): boolean => !!state && state.loaded,
);

export const isLoginError: MemoizedSelector<LoginState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  loginState,
  (state: LoginState): boolean => !!state && state.error,
);

export const isLoggingOut: MemoizedSelector<LogoutState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  logoutState,
  (state: LogoutState): boolean => !!state && state.loading,
);

export const isLogoutError: MemoizedSelector<LogoutState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  logoutState,
  (state: LogoutState): boolean => !!state && state.error,
);
