import { HorizontalAlignmentEnum } from './enums/horizontal-alignment.enum';
import { VerticalAlignmentEnum } from './enums/vertical-alignment.enum';
import { Widget } from './widget.model';
import { Style } from './interfaces/style.interface';

export class Column {
  constructor(
    public id: string,
    public alignment: { horizontal: HorizontalAlignmentEnum, vertical: VerticalAlignmentEnum },
    public children: Widget[],
    public style?: Style,
  ) {}
}
