import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Menu } from '@core/models';

@Component({
  selector: 'shared-layout-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent {
  public menu: Menu[];
  public active: string;
  public openedTab: string = null;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<MenuMobileComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { menu: Menu[], active: string },
  ) {
    this.menu = data.menu;
    this.active = data.active;
  }

  public toggleSub(name: string): void {
    this.openedTab = this.openedTab === name ? null : name;
  }

  public closeMenu(): void {
    this.bottomSheetRef.dismiss();
  }

  public isActive(page: string): boolean {
    return this.active === page;
  }

  public isOpen(page: string): boolean {
    return this.openedTab === page;
  }

  public trackById(idx: number, item: Menu): string {
    return item.id;
  }
}
