export class Menu {
  constructor(
    public id: string,
    public name: string,
    public path: string,
    public allowChildren: boolean = false,
    public children: Menu[] = [],
    public disabled: boolean = false,
  ) {}
}
