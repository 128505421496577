import { Component, Input } from '@angular/core';

import { Quote, Style, Widget, WidgetContent } from '@core/models';
import { ContentComponent } from '@shared/components/contents/content.component';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultPadding: string = '0';
  private defaultMargin: string = '0';
  private defaultHeight: string = 'auto';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get quotes(): Quote[] {
    return this.contentWidget?.content as Quote[];
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get style(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      height: this.contentStyle.height || this.defaultHeight,
    };
  }

  public getQuoteStyle(quote: Quote): { [key: string]: string } {
    const style: Style | any = quote.style || {};

    return {
      color: style.color || ColorsEnum.WHITE,
      fontSize: style.fontSize || '20px',
      fontWeight: style.fontWeight || '400',
      lineHeight: style.lineHeight || '24px',
      fontStyle: style.fontStyle || 'italic',
    };
  }

  public getQuoteWrapperStyle(quote: Quote): { [key: string]: string } {
    const style: Style | any = quote.style || {};

    return {
      top: style.top || '0',
      right: style.right || '0',
      bottom: style.bottom || '0',
      left: style.left || '0',
    };
  }
}
