import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const MAPS_API_KEY: string = 'AIzaSyD8gOaHd4ksSnQXJPoLzkhwFXF-9zJNgbA';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private currentApiStatus: BehaviorSubject<boolean> = new BehaviorSubject(Boolean(false));
  public currentApiStatus$: Observable<boolean> = this.currentApiStatus.asObservable();

  public markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    clickable: false,
  };

  constructor(private readonly httpClient: HttpClient) {
    httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}`, 'callback')
      .pipe(
        tap(() => this.loadMarker()),
        map(() => true),
        catchError(() => of(false)),
      ).subscribe(loaded => {
        this.currentApiStatus.next(loaded);
      });
  }

  public updateMarkerOptions(options: Partial<google.maps.MarkerOptions>): void {
    this.markerOptions = {
      ...this.markerOptions,
      ...options,
    };
  }

  private loadMarker(): void {
    this.markerOptions = {
      ...this.markerOptions,
      icon: {
        scaledSize: new google.maps.Size(45, 45, 'px', 'px'),
        url: 'assets/logo/marker.png',
      },
    };
  }
}
