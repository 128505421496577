export enum VerticalAlignmentEnum {
  TOP,
  CENTER,
  BOTTOM,
}

export const mappingVerticalAlignment: { [key in VerticalAlignmentEnum]: string } = {
  [VerticalAlignmentEnum.TOP]: 'flex-start',
  [VerticalAlignmentEnum.CENTER]: 'center',
  [VerticalAlignmentEnum.BOTTOM]: 'flex-end',
};
