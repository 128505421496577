import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MenuMobileComponent } from '@shared/modals';
import { Menu } from '@core/models';

@Component({
  selector: 'shared-layout-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {
  @Input() public menu: Menu[];

  private active: string = null;
  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly bottomSheet: MatBottomSheet,
  ) {
    this.route.paramMap.pipe(
      takeUntil(this.unsubscribeAll),
    ).subscribe((params: ParamMap) => {
      this.setActive(params.get('page') || 'home');
      timer(5).subscribe(() => this.bottomSheet.dismiss());
    });
  }

  public openBottomSheet(): void {
    this.bottomSheet.open(MenuMobileComponent, {
      panelClass: 'menu-mobile',
      data: { menu: this.menu, active: this.active },
    });
  }

  public setActive(page: string): void {
    this.active = page;
  }

  public isActive(page: string): boolean {
    return (this.active || 'home') === page;
  }

  public trackById(idx: number, item: Menu): string {
    return item.id;
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next(false);
    this.unsubscribeAll.complete();
  }
}
