<div class="contact" [ngStyle]="style">
  <div class="contact__info">
    <div class="info__name" *ngIf="displayField(['company', 'name'])">
      <h2 *ngIf="displayField('company')">{{ contact.company }}</h2>
      <h3 *ngIf="displayField('name')">{{ contact.name }}</h3>
    </div>

    <div class="info__data" *ngIf="displayField(['email', 'phone', 'address'])">
      <p *ngIf="displayField('email')">{{ contact.email }}</p>
      <p *ngIf="displayField('phone')">{{ contact.phone }}</p>
      <p *ngIf="displayField('address')">{{ contact.address }}</p>
    </div>

    <div class="info__social-media" *ngIf="displayField(['facebook', 'linkedIn'])">
      <p>Pour garder le lien :</p>
      <p>
        <button *ngIf="displayField('facebook')" mat-mini-fab class="social-media__button" (click)="goTo(contact.facebook)">
          <shared-svg-icon class="icon" icon="facebook"></shared-svg-icon>
        </button>

        <button *ngIf="displayField('linkedIn')" mat-mini-fab class="social-media__button" (click)="goTo(contact.linkedIn)">
          <shared-svg-icon class="icon" icon="linked-in"></shared-svg-icon>
        </button>
      </p>
    </div>

  </div>

  <div class="contact__map">
    <div *ngIf="(contact.lat && contact.lng) && (apiLoaded$ | async)">
      <google-map height="300px" width="100%" [options]="options">
        <map-marker [options]="markerOptions"></map-marker>
      </google-map>
    </div>
  </div>
</div>