<div class="contact-content-form" [ngStyle]="styleBlock">
  <shared-contact-form
    [displayCancelButton]="false"
    [displayTitle]="false"
    [isLocked]="locked$ | async"
    [isSending]="sending$ | async"
    [isSent]="sent$ | async"
    [hasError]="error$ | async"
    (valid)="performContact($event)"
  ></shared-contact-form>
</div>


