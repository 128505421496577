import { Injectable } from '@angular/core';
import { environment } from '@env';

const {
  i18n,
  api,
} = environment;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public readonly languages: string[];
  public readonly locales: string[];
  public readonly defaultLocale: string;
  public readonly fallbackLocale: string;
  public readonly i18nStorageKey: string;
  public readonly apiUrl: string;
  public readonly origin: string;

  constructor() {
    this.apiUrl = api.baseUrl;
    this.origin = api.origin;
    this.defaultLocale = i18n.defaultLocale;
    this.fallbackLocale = i18n.fallback;
    this.languages = i18n.languages;
    this.locales = i18n.locales;
    this.i18nStorageKey = i18n.storageKey;
  }

  public getValue(key: string): string {
    return localStorage.getItem(key);
  }

  public setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public clear(): void {
    localStorage.clear();
  }
}
