import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Style, Testimony, Widget, WidgetContent } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';
import { ContentComponent } from '@shared/components/contents/content.component';

@Component({
  selector: 'shared-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.scss'],
})
export class TestimoniesComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' | 'space-between' = 'flex-start';
  private defaultPadding: string = '0';
  private defaultMargin: string = '1rem 0';
  private defaultColor: string = '#FFFFFF';
  private currentTestimony: Testimony;

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get testimonies(): Testimony[] {
    return this.contentWidget?.content as Testimony[];
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get currentColor(): string {
    return this.getTestimonyBlockStyle(this.currentTestimony)?.color || this.style.color;
  }

  public get style(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      color: this.contentStyle.color || this.defaultColor,
    };
  }

  constructor(
    private readonly router: Router,
  ) {}

  public getTestimonyBlockStyle(testimony: Testimony): { [key: string]: string } {
    if (!testimony) {
      return;
    }

    const style: Style | any = testimony.style || {};

    return {
      alignItems: style.align || 'center',
      justifyContent: style.justify || 'space-between',
      padding: style.padding || '3rem 5rem',
      margin: style.margin || '0',
      backgroundColor: style.backgroundColor || ColorsEnum.PURPLE,
      color: style.color || ColorsEnum.WHITE,
    };
  }

  public getTestimonyTitleStyle(testimony: Testimony): { [key: string]: string } {
    const style: Style | any = testimony.style || {};

    return {
      color: style.color || ColorsEnum.WHITE,
      fontSize: '24px',
      fontWeight: style.fontWeight || '400',
      lineHeight: '28px',
      fontStyle: style.fontStyle || 'italic',
    };
  }

  public getTestimonyTextStyle(testimony: Testimony): { [key: string]: string } {
    const style: Style | any = testimony.style || {};

    return {
      color: style.color || ColorsEnum.WHITE,
      fontSize: style.fontSize || '18px',
      fontWeight: style.fontWeight || '400',
      lineHeight: style.lineHeight || '21px',
      fontStyle: style.fontStyle || 'italic',
    };
  }

  public onClick(testimony: Testimony): void {
    this.router.navigate(testimony.menu.path.split('/'));
  }
}
