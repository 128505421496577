import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { Menu } from '@core/models';
import { loadLinks, loadMenu } from '@core/store/actions';
import { links, menu } from '@core/store/selectors';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public menu$: Observable<Menu[]> = this.store.pipe(select(menu));
  public links$: Observable<Menu[]> = this.store.pipe(select(links));

  constructor(
    private readonly store: Store,
  ) {
    this.store.dispatch(loadMenu());
    this.store.dispatch(loadLinks());
  }
}
