import { Moment } from 'moment';

import { Page } from './page.model';

export class BlogArticle {
  constructor(
    public id: string,
    public image: string,
    public title: string,
    public summary: string,
    public content: Page,
    public createdAt: Moment,
    public publishedAt: Moment,
    public views: number = 0,
    public shares: number = 0,
    public disabled: boolean = false,
    public video?: string,
  ) {}
}
