<div class="blog">
  <div class="blog__heading">
    <div class="heading__title"><h1>Blog</h1></div>
    <div class="heading__search">search</div>
  </div>

  <div class="blog__main">
    <div class="main__filters">
      <div class="filters__categories">
        <h3>Catégories</h3>

        <div class="categories__category">
          <p>Catégorie 1</p>
          <shared-separator [widget]="separator"></shared-separator>
        </div>

        <div class="categories__category">
          <p>Catégorie 2</p>
          <shared-separator [widget]="separator"></shared-separator>
        </div>

        <div class="categories__category">
          <p>Catégorie 3</p>
          <shared-separator [widget]="separator"></shared-separator>
        </div>

        <div class="categories__category">
          <p>Catégorie 4</p>
          <shared-separator [widget]="separator"></shared-separator>
        </div>
      </div>

      <div class="filters__top">
        <h3>Top articles</h3>
      </div>
    </div>

    <div class="main__content">
      <div class="content__articles">articles</div>
      <div class="content__pagination">pagination</div>
    </div>
  </div>
</div>