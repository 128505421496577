import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ClesdefaState, getClesdefaState } from '@core/store/reducers';
import { ContactState } from '@core/store/reducers/contact/contact.reducer';

export const getContactState: MemoizedSelector<unknown, ContactState, DefaultProjectorFn<ContactState>> = createSelector(
  getClesdefaState,
  (state: ClesdefaState): ContactState => !!state && state.contact,
);

export const isSending: MemoizedSelector<ContactState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getContactState,
  (state: ContactState): boolean => !!state && state.loading,
);

export const isSent: MemoizedSelector<ContactState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getContactState,
  (state: ContactState): boolean => !!state && state.loaded,
);

export const isLocked: MemoizedSelector<ContactState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getContactState,
  (state: ContactState): boolean => !!state && state.locked,
);

export const isError: MemoizedSelector<ContactState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  getContactState,
  (state: ContactState): boolean => !!state && state.error,
);
