import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Page } from '@core/models';
import { loadPage } from '@core/store/actions';

@Injectable({ providedIn: 'root' })
export class PageResolver implements Resolve<Page> {
  constructor(
    private readonly store: Store,
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    let path: string = `/${route.paramMap.get('page') || ''}`;

    if (route.paramMap.get('slug')) {
      path += `/${route.paramMap.get('slug')}`;
    }

    this.store.dispatch(loadPage({ path }));
  }
}
