<div class="layout-menu">
  <div class="layout-menu__mobile">
    <button mat-icon-button (click)="openBottomSheet()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="layout-menu__desktop">
    <ng-container *ngFor="let tab of menu; trackBy:trackById">
      <ng-container *ngIf="tab.allowChildren; else link">
        <button mat-button class="desktop__tab" [ngClass]="{ active: isActive(tab.id) }" [matMenuTriggerFor]="menu">
          {{ tab.name }}
        </button>

        <mat-menu #menu="matMenu" class="menu-desktop">
          <ng-container *ngFor="let subtab of tab.children; trackBy:trackById">
            <button routerLinkActive="active" [routerLink]="subtab.path" mat-menu-item>
              {{ subtab.name }}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <ng-template #link>
        <a class="desktop__tab" [ngClass]="{ active: isActive(tab.id) }" [routerLink]="tab.path">
          {{ tab.name }}
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>
