import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';

import { SKELETON_LOADER_CONFIG, SkeletonLoaderConfig, SkeletonLoaderConfigTheme } from '../skeleton-loader.types';

const APPEARANCES: string[] = ['circle', 'line', 'block', 'button', 'separator', ''];
const ANIMATIONS: string[] = ['progress', 'progress-dark', 'pulse', 'false'];

@Component({
  selector: 'shared-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent implements OnInit, OnChanges {
  @Input() public count: SkeletonLoaderConfig['count'];
  @Input() public loadingText: SkeletonLoaderConfig['loadingText'];
  @Input() public appearance: SkeletonLoaderConfig['appearance'];
  @Input() public animation: SkeletonLoaderConfig['animation'];
  @Input() public ariaLabel: SkeletonLoaderConfig['ariaLabel'];
  @Input() public theme: SkeletonLoaderConfigTheme;
  @Input() public height: string;

  public items: any[];

  public get classes(): { [key: string]: boolean } {
    return {
      [this.appearance]: this.appearance !== '',
      [this.animation as string]: !['false', false].includes(this.animation),
    };
  }

  public get style(): { [key: string]: string } {
    return {
      ...this.theme,
      height: this.canAddExtraHeight(this.appearance) ? this.height : '',
    };
  }

  constructor(@Inject(SKELETON_LOADER_CONFIG) @Optional() config?: SkeletonLoaderConfig) {
    const {
      appearance = 'line',
      animation = 'progress',
      theme = null,
      loadingText = 'Loading...',
      count = 1,
      ariaLabel = 'loading',
    } = config || {};

    this.appearance = appearance;
    this.animation = animation;
    this.theme = theme;
    this.loadingText = loadingText;
    this.count = count;
    this.items = [];
    this.ariaLabel = ariaLabel;
  }

  public ngOnInit(): void {
    this.validateInputValues();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Avoiding multiple calls for the same input in case there's no changes in the fields
    // Checking if the fields that require validation are available and if they were changed
    // In case were not changed, we stop the function. Otherwise, `validateInputValues` will be called.
    const cancelChangeEvent: string[] = ['count', 'animation', 'appearance'];
    const hasToCancel: boolean = !!cancelChangeEvent.find(key => changes[key] && (changes[key].isFirstChange() || changes[key].previousValue === changes[key].currentValue));

    if (hasToCancel) {
      return;
    }

    this.validateInputValues();
  }

  public trackByFn(i: number): string {
    return `skeleton-${i}`;
  }

  private canAddExtraHeight(type: string): boolean {
    return ['block'].includes(type);
  }

  private validateInputValues(): void {
    // Checking if it's receiving a numeric value (string having ONLY numbers or if it's a number)
    if (!/^\d+$/.test(`${this.count}`)) {
      // Shows error message only in Development
      if (isDevMode()) {
        console.error(
          '`SkeletonLoaderComponent` need to receive \'count\' a numeric value. Forcing default to "1".',
        );
      }
      this.count = 1;
    }

    this.items.length = this.count;

    if (ANIMATIONS.indexOf(String(this.animation)) === -1) {
      // Shows error message only in Development
      if (isDevMode()) {
        console.error(`\`SkeletonLoaderComponent\` need to receive 'animation' as: ${ANIMATIONS.join(', ')}. Forcing default to "progress".`);
      }

      this.animation = 'progress';
    }

    if (APPEARANCES.indexOf(String(this.appearance)) === -1) {
      // Shows error message only in Development
      if (isDevMode()) {
        console.error(`\`SkeletonLoaderComponent\` need to receive 'appearance' as: ${APPEARANCES.join(', ')}. Forcing default to "''".`);
      }

      this.appearance = '';
    }
  }
}
