export class ContactInfo {
  constructor(
    public name: string = 'Anne FAVIER',
    public company: string = 'Clés de Fa',
    public phone: string = '06 14 64 28 02',
    public email: string = 'afavier@clesdefa.com',
    public address: string = '88 rue de la Cigrogne, 38500 Voiron',
    public lat: number = 45.353288,
    public lng: number = 5.586467,
    public facebook: string = 'https://www.facebook.com/Clesdefa',
    public linkedIn: string = 'https://www.linkedin.com/in/anne-favier-0a880495/',
  ) {}
}
