<div class="home-loader">
  <div class="home-loader__multi-blocks">
    <shared-skeleton-loader count="2" appearance="block" height="250px"></shared-skeleton-loader>
  </div>

  <div class="home-loader__button">
    <shared-skeleton-loader count="1" appearance="button"></shared-skeleton-loader>
  </div>

  <!-- TODO: blog-->
  <div *ngIf="false" class="home-loader__multi-blocks">
    <shared-skeleton-loader count="3" appearance="block" height="300px"></shared-skeleton-loader>
  </div>

  <div class="home-loader__separator">
    <shared-skeleton-loader count="1" appearance="separator"></shared-skeleton-loader>
  </div>

  <div class="home-loader__banner">
    <shared-skeleton-loader count="1" appearance="block"></shared-skeleton-loader>
  </div>
</div>