import { Injectable } from '@angular/core';

import { lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import moment from 'moment';

import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method';
import { ConfigService } from '@shared/services';
import { LoginRequest, LoginResponse } from '@core/models';
import { environment } from '@env';

const { authentication: { jwtTokenKey, jwtExpireAtKey } } = environment;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apiAuthUrl: string = `${ this.configService.apiUrl }/auth`;
  private readonly jwtTokenKey: string = jwtTokenKey;
  private readonly jwtExpireAtKey: string = jwtExpireAtKey;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public login(form: LoginRequest): Observable<LoginResponse> {
    return this.httpService
      .perform<LoginRequest, LoginResponse>(HttpMethod.post, `${this.apiAuthUrl}`, form)
      .pipe(
        tap(obj => this.setSession(obj)),
      );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.httpService
      .perform<void, boolean>(HttpMethod.get, `${this.apiAuthUrl}/IsAuthenticated`);
  }

  public async isSessionAlive(): Promise<boolean> {
    const isValid: boolean = this.isJwtValid();

    if (isValid) {
      await this.renewSession();
    }

    return isValid;
  }

  public logout(): Observable<boolean> {
    return this.httpService
      .perform<void, boolean>(HttpMethod.get, `${this.apiAuthUrl}/logout`);
  }

  public clearLocalStorage(): void {
    this.configService.clear();
  }

  public getToken(): string {
    return this.configService.getValue(this.jwtTokenKey);
  }

  private async renewSession(): Promise<void> {
    await lastValueFrom(this.httpService
      .perform<void, LoginResponse>(HttpMethod.get, `${this.apiAuthUrl}/RenewToken`)
      .pipe(
        tap(obj => this.setSession(obj)),
      ));
  }

  private setSession(sessionInfo: LoginResponse): void {
    this.configService.setValue(this.jwtExpireAtKey, sessionInfo.expireAt.toISOString());
    this.configService.setValue(this.jwtTokenKey, sessionInfo.token);
  }

  private isJwtValid(): boolean {
    const expireAt: string = this.configService.getValue(this.jwtExpireAtKey);

    return expireAt && moment(expireAt).local() > moment();
  }
}
