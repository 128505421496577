import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SwiperModule } from 'swiper/angular';

import { sharedModules } from './modules';
import { sharedComponents } from './components';
import { sharedServices } from './services';
import { sharedGuards } from './guards';
import { sharedDirectives } from './directives';
import { sharedPipes } from './pipes';
import { sharedValidators } from './validators';
import { sharedModals } from './modals';
import { sharedHelpers } from './helpers';

export const appModules: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // Add other modules here
];

export const materialModules: any[] = [
  GoogleMapsModule,
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatMenuModule,
  MatBottomSheetModule,
  MatExpansionModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  SwiperModule,
  // Add material modules here
  ...sharedModules,
];

export const appComponents: any[] = [
  ...sharedModals,
  ...sharedComponents,
  // Add other components here
];

export const appValidators: any[] = [
  ...sharedValidators,
  // Add other validators here
];

export const appDialogs: any[] = [
  ...sharedModals,
  // Add other dialog here
];

export const appServices: any[] = [
  ...sharedServices,
  // Add other services here
];

export const appGuards: any[] = [
  ...sharedGuards,
  // Add other guards here
];

export const appDirectives: any[] = [
  ...sharedDirectives,
  // Add other directives here
];

export const appPipes: any[] = [
  ...sharedPipes,
  // Add other pipes here
];

export const appHelpers: any[] = [
  ...sharedHelpers,
];
