import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpMethod } from '@core/services/http/http-method';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
  ) { }

  public perform<TRequest, TResponse>(
    method: HttpMethod,
    url: string,
    body: TRequest = null,
    params: any = null,
  ): Observable<TResponse> {
    if (!body && [HttpMethod.post, HttpMethod.patch, HttpMethod.put].includes(method)) {
      return throwError(() => 'Invalid request data');
    }

    return this.httpClient.request<TResponse>(method.toUpperCase(), url, {
      responseType: 'json',
      body,
      params,
      headers: this.getHttpOptions(),
    }).pipe(
      catchError(error => this.handleError<TResponse>(error)),
    );
  }

  private getHttpOptions(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': `${this.configService.origin}`,
    });
  }

  private handleError<T>(error: any): Observable<T> {
    return throwError(() => `Error: ${error.error || error.message || error}`);
  }
}
