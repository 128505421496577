import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ClesdefaState, getClesdefaState, LayoutState, MenuState } from '@core/store/reducers';
import { Menu } from '@core/models';

export const getLayoutState: MemoizedSelector<unknown, LayoutState, DefaultProjectorFn<LayoutState>> = createSelector(
  getClesdefaState,
  (state: ClesdefaState): LayoutState => !!state && state.layout,
);

/* MENU */
export const menuState: MemoizedSelector<unknown, MenuState, DefaultProjectorFn<MenuState>> = createSelector(
  getLayoutState,
  (state: LayoutState): MenuState => !!state && state.menu,
);

export const isMenuLoading: MemoizedSelector<MenuState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  menuState,
  (state: MenuState): boolean => !!state && state.loading,
);

export const isMenuLoaded: MemoizedSelector<MenuState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  menuState,
  (state: MenuState): boolean => !!state && state.loaded,
);

export const menuError: MemoizedSelector<MenuState, string, DefaultProjectorFn<string>> = createSelector(
  menuState,
  (state: MenuState): string => !!state && state.error,
);

export const menu: MemoizedSelector<MenuState, Menu[], DefaultProjectorFn<Menu[]>> = createSelector(
  menuState,
  (state: MenuState): Menu[] => !!state && state.data,
);

/* LINKS */
export const linksState: MemoizedSelector<unknown, MenuState, DefaultProjectorFn<MenuState>> = createSelector(
  getLayoutState,
  (state: LayoutState): MenuState => !!state && state.links,
);

export const isLinksLoading: MemoizedSelector<MenuState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  linksState,
  (state: MenuState): boolean => !!state && state.loading,
);

export const isLinksLoaded: MemoizedSelector<MenuState, boolean, DefaultProjectorFn<boolean>> = createSelector(
  linksState,
  (state: MenuState): boolean => !!state && state.loaded,
);

export const linksError: MemoizedSelector<MenuState, string, DefaultProjectorFn<string>> = createSelector(
  linksState,
  (state: MenuState): string => !!state && state.error,
);

export const links: MemoizedSelector<MenuState, Menu[], DefaultProjectorFn<Menu[]>> = createSelector(
  linksState,
  (state: MenuState): Menu[] => !!state && state.data,
);
