import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { send } from '@core/store/actions';
import { isError, isLocked, isSending, isSent } from '@core/store/selectors';
import { Field, Form, FormSubject, Style, Widget, WidgetContent } from '@core/models';
import { ContentComponent } from '@shared/components/contents/content.component';

@Component({
  selector: 'shared-contact-content-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormContentComponent implements ContentComponent {
  @Input() public widget: Widget;

  public sent$: Observable<boolean> = this.store.pipe(select(isSent));
  public sending$: Observable<boolean> = this.store.pipe(select(isSending));
  public locked$: Observable<boolean> = this.store.pipe(select(isLocked));
  public error$: Observable<boolean> = this.store.pipe(select(isError));

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultPadding: string = '30px';
  private defaultMargin: string = '0';

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { fields: Field[], subjects: FormSubject[] } {
    return this.contentWidget?.content as { fields: Field[], subjects: FormSubject[] };
  }

  public get styleBlock(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      maxWidth: this.contentStyle.maxWidth || 'unset',
    };
  }

  constructor(
    private readonly store: Store,
  ) {}

  public performContact(contact: Form): void {
    this.store.dispatch(send({
      ...contact,
    }));
  }
}
