export enum HorizontalAlignmentEnum {
  LEFT,
  CENTER,
  RIGHT,
}

export const mappingHorizontalAlignment: { [key in HorizontalAlignmentEnum]: string } = {
  [HorizontalAlignmentEnum.LEFT]: 'flex-start',
  [HorizontalAlignmentEnum.CENTER]: 'center',
  [HorizontalAlignmentEnum.RIGHT]: 'flex-end',
};
