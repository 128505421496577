import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { send } from '@core/store/actions';
import { isError, isLocked, isSending, isSent } from '@core/store/selectors';
import { Form } from '@core/models';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  public sent$: Observable<boolean> = this.store.pipe(select(isSent));
  public sending$: Observable<boolean> = this.store.pipe(select(isSending));
  public locked$: Observable<boolean> = this.store.pipe(select(isLocked));
  public error$: Observable<boolean> = this.store.pipe(select(isError));

  constructor(
    private readonly store: Store,
    public dialogRef: MatDialogRef<ContactFormComponent>,
  ) {}

  public onCancel(): void {
    this.dialogRef.close();
  }

  public performContact(contact: Form): void {
    this.store.dispatch(send({
      ...contact,
    }));
  }
}
