/* eslint-disable max-len */
import moment from 'moment';

import {
  ColorsEnum,
  Column,
  HorizontalAlignmentEnum,
  Page,
  Quote,
  Row,
  RowPatternEnum, Testimony,
  VerticalAlignmentEnum,
  Widget,
  WidgetEnum,
} from '@core/models';

import {
  menuExpertiseAcpMock,
  // menuExpertiseCdeMock, // manque un témoignage
  menuExpertiseCicMock, menuExpertiseFdeMock,
  menuHomeMock,
} from '../menu.mock';

export const homeMock: Page = new Page('home', [
  new Row('home1', 0, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('home1-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('home1-1-1', 1, WidgetEnum.TEXT, {
        style: { padding: '5px 30px 0' },
        content: '<h1>QUI SUIS-JE ?</h1>',
      }),
      new Widget('home1-1-2', 1, WidgetEnum.TEXT, {
        style: { padding: '5px 30px 0' },
        content: `
        <p>Mon parcours professionnel et personnel m’invite aujourd’hui à rassembler et transmettre mes compétences à votre service pour votre bien-être.</p>
        <p>Mon projet est de soutenir les équipes qui travaillent autour des actions éducatives de la petite enfance, l’enfance et les adolescents, autour des relations parents/enfants, de la cohésion d’équipe, pour définir le projet commun et mieux répondre aux besoins de service et au service des citoyens.</p>
        <p>Autonome et créative, j’aspire à accompagner des équipes et personnes afin qu’elles donnent sens à leurs actions.</p>
        `,
      }),
    ]),
    new Column('home1-2', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.BOTTOM }, [
      new Widget('home1-2-1', 0, WidgetEnum.QUOTES, {
        style: { width: '100%', height: '350px', padding: '0 30px 10px' },
        content: [
          new Quote(
            '1',
            'Faire grandir les personnes dans le respect et la diversité',
            '/assets/images/quote-1.jpg',
            null,
            { top: '-10px' },
          ),
          new Quote(
            '2',
            'Et si un professionnel vous accompagnait vers la réussite ?',
            '/assets/images/quote-2.png',
            null,
            { top: '60%' },
          ),
          new Quote(
            '3',
            '« Savoir écouter, c’est posséder, outre le sien, (un peu) le cerveau des autres. »',
            '/assets/images/quote-3.jpg',
            'Léonard de Vinci',
            { color: ColorsEnum.BLACK, top: '50%' },
          ),
          new Quote(
            '4',
            'Qu’est-ce qui rendrait la vie plus belle ?',
            '/assets/images/quote-4.png',
            null,
            { color: ColorsEnum.BLUE },
          ),
        ],
      }),
    ]),
  ], { align: 'stretch' }),
  new Row('home2', 1, RowPatternEnum.SIMPLE, [
    new Column('home2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('home2-1-1', 1, WidgetEnum.BUTTON, {
        style: {},
        content: { text: 'A propos de moi', path: '/about-me' },
      }),
    ]),
  ], { padding: '0 30px' }),
  // TODO: blog
  // new Row('home3', 2, RowPatternEnum.SIMPLE, [
  //   new Column('home3-1', { vertical: VerticalAlignmentEnum.CENTER, horizontal: HorizontalAlignmentEnum.CENTER }, [
  //     new Widget('home3-1-1', 1, WidgetEnum.ARTICLES, {
  //       style: { padding: '0' },
  //       content: [
  //         new BlogArticle(
  //           '1',
  //           'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
  //           'Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a nunc neque. Ut nec porta enim. Cras hendrerit semper gravida. Cras fermentum lectus magna, vel placerat tortor vehicula sed.',
  //           null,
  //           moment(),
  //           moment(),
  //         ),
  //         new BlogArticle(
  //           '2',
  //           'https://img-19.ccm2.net/iBYO1DOif2mcoMT7crnZ0Yy3XaU=/480x270/smart/b829396acc244fd484c5ddcdcb2b08f3/ccmcms-commentcamarche/20494859.jpg',
  //           'Ut nec porta enim',
  //           'Nullam id odio sit amet purus tincidunt ullamcorper a non sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam erat volutpat.',
  //           null,
  //           moment(),
  //           moment(),
  //         ),
  //         new BlogArticle(
  //           '3',
  //           'https://img-19.ccm2.net/WNCe54PoGxObY8PCXUxMGQ0Gwss=/480x270/smart/d8c10e7fd21a485c909a5b4c5d99e611/ccmcms-commentcamarche/20456790.jpg',
  //           'Cras hendrerit semper gravida',
  //           'Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam eget tristique massa, at euismod nulla. Nam tempor rutrum volutpat.',
  //           null,
  //           moment(),
  //           moment(),
  //         ),
  //       ],
  //     }),
  //   ]),
  // ], { padding: '40px 30px 0' }),
  new Row('home4', 3, RowPatternEnum.SIMPLE, [
    new Column('home4-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { padding: '10px' },
        content: null,
      }),
    ]),
  ], {}),
  new Row('home5', 4, RowPatternEnum.SIMPLE, [
    new Column('home5-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('home5-1-1', 1, WidgetEnum.TESTIMONIES, {
        style: { padding: '0', margin: '0', width: '100%', height: 'auto' },
        content: [
          new Testimony(
            '1',
            menuExpertiseCicMock,
            `
              <p>
                Merci Anne pour ton aide précieuse au cours de 2 séances de coaching préparatoires à une rencontre avec plusieurs professionnels de la santé en vue d'un travail dans l'accompagnement au sein d'une structure extra-hospitalière.
                Tu m'as permis de déterminer ce qui était important à dire, et de surtout parler de tout ce qui est positif dans mon parcours.
                Ainsi, lors de cette rencontre, je me suis sentie à l'aise pour évoquer mon parcours très atypique et parler de toutes ces riches missions vécues précédemment.
                Mais surtout, j'ai pu être dans une bonne dynamique d'échange avec tous ces professionnels.
              </p>
              <p>J'ai décroché le job et c'est aussi grâce à toi. Grand merci Anne.</p>`,
            'Catherine, déc 2020',
            'En savoir plus sur le Coaching individuel ou collectif',
            moment('2020-12-01'),
            { backgroundColor: ColorsEnum.PURPLE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          ),
          new Testimony(
            '2',
            menuExpertiseCicMock,
            `
              <p>Dans ma recherche d'emploi, après avoir obtenu un entretien d'embauche, j'ai pu me préparer avec Anne à mettre en avant certaines des compétences attendues.</p>
              <p>
                Autour d'une tasse de thé, bien installé dans son espace cosy, elle m'a aidé à construire des arguments pour répondre à différentes questions qui auraient pu m’être posées. 
                Mené de manière bienveillante, elle m'a donné confiance pour m'introspecter et comprendre l’évolution que je traverse et qui amène ce changement de carrière professionnelle.
                <br>Nous avons ensemble identifié quels seraient les objectifs et les critères de réussite d'un entretien qui se passerait bien.
              </p>
              <p>Grâce à elle, lors de l'entretien j'ai pu m'exposer, sans appréhension, en toute connaissance des forces et des faiblesses de ma candidature.</p>
              <p>Merci Anne.</p>`,
            'Julien, déc 2020',
            'En savoir plus sur le Coaching individuel ou collectif',
            moment('2020-12-01'),
            { backgroundColor: ColorsEnum.ORANGE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          ),
          new Testimony(
            '3', menuExpertiseAcpMock,
            `
              <p>Un énorme merci pour nous avoir donné l’opportunité de participer à un groupe de co-développement.</p>
              <p>
                J’en ressors enrichie de nouvelles relations, de nouvelles idées. 
                Idées qui me sont venues soit de par la discussion sur ma thématique mais aussi grâce à celle des autres. 
                En tant que coach, j’ai été inspirée par les questions posées auxquelles je n’aurai pas pensé.
              </p>
              <p>Je ne peux que recommander de participer à des séances de co-développement, pour la dynamique de groupe et l’intelligence collective qui amène de nouvelles solutions.</p>
              <p>En tant que jeune coach qui lance son activité, cela m’a été très bénéfique…</p>
            `,
            'Coach en Codéveloppement, 25 juin 2020',
            'En savoir plus sur l\'Animation de codéveloppement professionnel',
            moment('2020-06-25'),
            { backgroundColor: ColorsEnum.BLUE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          ),
          new Testimony(
            '4', menuExpertiseAcpMock,
            `<p>
              Anne FAVIER a animé un groupe de codéveloppement sur Grenoble et environs dont j'ai eu la chance de faire partie.<br>
              Cela m'a permis de professionnaliser la démarche de codéveloppement que j'appliquais de façon intuitive avec des demandeurs d'emploi.<br>
              Avec l'humour jamais bien loin, Anne réussit à mettre le groupe à l'aise, puisant ses "brise-glaces" et autres "tours de météo", dans ses ressources pédagogiques tirées de l'éducation populaire.<br>
              Avec beaucoup de méthode et de doigté, elle guide les participants pour que chacun reste dans son rôle, à commencer par le "client" qu'elle conduit loin pour lui faire définir la problématique la plus juste à poser.<br>
              J'ai été témoin à chaque fois, y compris comme "client", de la très grande fécondité des échanges, qui se sont parfois risqués, avec la même efficacité, à aborder des thématiques parfois très personnelles.<br>
              Le codéveloppement, et ce n'est pas le moindre de ses mérites, ouvre à chacun, y compris aux consultants, des champs de possible nouveaux. Ainsi ai-je été témoin de la transformation, séance après séance, de certains qui ont pris davantage d'assurance, parfois même retrouvé du travail"
            </p>`,
            'Denis, déc 2020',
            'En savoir plus sur l\'Animation de codéveloppement professionnel',
            moment('2020-12-01'),
            { backgroundColor: ColorsEnum.PURPLE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          ),
          // new Testimony(
          //   '5', menuExpertiseCdeMock,
          //   'Cras fermentum lectus magna, vel placerat tortor vehicula sed.',
          //   '',
          //   'En savoir plus sur la Consultation dans le domaine éducatif',
          //   moment(),
          //   { backgroundColor: ColorsEnum.ORANGE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          // ),
          new Testimony(
            '6', menuExpertiseFdeMock,
            `
            <p>
              Bon rythme, soutenu et consistant mais dans la légèreté. De belles combinaisons entre les temps de réflexions et des temps dynamiques. 
              Pas de stress, pas de temps morts. La courbe d’intensité a été respectée. 
              <br>Nous sommes venus avec plaisir les 3 jours de formation. 
              <br>Merci à la formatrice.
            </p>
            `,
            'Groupe de 15 stagiaires, oct 2020',
            'En savoir plus sur la Formation dans le domaine éducatif',
            moment('2020-10-08'),
            { backgroundColor: ColorsEnum.BLUE, color: ColorsEnum.WHITE, align: 'center', justify: 'space-between', padding: '3rem 5rem', margin: '0' },
          ),
        ],
      }),
    ]),
  ], { padding: '0 30px 50px' }),
], menuHomeMock);
/* eslint-enable max-len */
