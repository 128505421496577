import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as PageActions from '@core/store/actions';
import { Page } from '@core/models';
import { PageService } from '@core/services';

@Injectable()
export class PageEffects {
  public loadPage$: any = createEffect(() => this.actions$.pipe(
    ofType(PageActions.loadPage),
    map((action: { path: string }) => action.path),
    switchMap((path: string) =>
      this.pageService.loadPage(path).pipe(
        map((response: Page) => PageActions.loadPageSuccess({ page: response })),
        catchError((error: string) => of(PageActions.loadPageFail({ error }))),
      ),
    ),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly pageService: PageService,
  ) { }
}
