import { Row } from './row.model';
import { Menu } from './menu.model';
import { PageEnum } from './enums/page.enum';

export class Page {
  constructor(
    public id: string,
    public content: Row[],
    public menu: Menu,
    public realId?: PageEnum,
  ) {}
}
