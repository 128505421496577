import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() public route: string;

  public get isHome(): boolean {
    return this.route === '/';
  }

  public get isExpertise(): boolean {
    return this.route.includes('expertise');
  }

  public get isAbout(): boolean {
    return this.route.includes('about');
  }

  public get isContact(): boolean {
    return this.route.includes('contact');
  }

  public get isBigPage(): boolean {
    return this.route.includes('legal-notice') || this.route.includes('privacy-policy');
  }
}
