/* eslint-disable max-len */

export const privacyPolicyMock: string = `
  <h2>Introduction</h2>
  <p>Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée. C’est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.</p>
 
  <h2>Collecte des renseignements personnels</h2>
  <p>Les informations suivantes sont susceptibles d'être traités lors de la demande de contact. Aucune de ces données n'est enregistrée dans nos bases de données. Elles sont directement transférées par mail via SparkPost.</p>
  <ul>
    <li>Prénom</li>
    <li>Adresse électronique</li>
    <li>Numéro de téléphone / télécopieur</li>
  </ul>
  <p>Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l’interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations vous concernant.</p>
 
  <h2>Formulaires&nbsp;et interactivité :</h2>
  <p>Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :</p>
  <ul>
    <li>Prénom</li>
    <li>Adresse électronique</li>
    <li>Numéro de téléphone / télécopieur</li>
  </ul>
  <p>Nous utilisons les renseignements ainsi collectés uniquement pour compléter la demande de contact.</p>
  <p>Vos renseignements sont collectés par le biais de l’interactivité pouvant s’établir entre vous et notre site Web et ce, de la façon suivante :</p>
  <ul>
    <li>Formulaire de contact</li>
    <li>Demande de correspondance</li>
  </ul>
 
  <h2>Droit d’opposition et de retrait</h2>
  <p>Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements personnels.</p>
  <p>Le droit d’opposition s’entend comme étant la possibilité offerte aux internautes de refuser que leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte.</p>
  <p>Le droit de retrait s’entend comme étant la possibilité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.</p>

  <p><strong>Pour pouvoir exercer ces droits, vous pouvez écrire par courrier à :</strong></p>
  <p>
    Clés de Fa – DPO, Anne FAVIER
    <br>88 rue de la Cigrogne 38500 VOIRON.
  </p>
  
  <h2>Droit d’accès</h2>
  <p>Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant.</p>
  <p><strong>L’exercice de ce droit se fera via courrier :</strong></p>
  <p>
    Clés de Fa – DPO, Anne FAVIER
    <br>88 rue de la Cigrogne 38500 VOIRON.
  </p>
  
  <h2>Sécurité</h2>
  <p>Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.</p>
  <p>Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :</p>
  <ul>
    <li>Gestion des accès - personne autorisée</li>
    <li>Gestion des accès - personne concernée</li>
  </ul>
  <p>Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsque l’on utilise Internet pour transmettre des renseignements personnels.</p>
  
  <h2>Législation</h2>
  <p>Nous nous engageons à respecter les dispositions législatives énoncées dans la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
`;
