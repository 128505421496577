import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ContactService } from '@core/services/contact/contact.service';
import * as ContactActions from '@core/store/actions';
import { Form } from '@core/models';

@Injectable()
export class ContactEffects {
  public send$: any = createEffect(() => this.actions$.pipe(
    ofType(ContactActions.send),
    switchMap((action: Form) =>
      this.contactService.send(action).pipe(
        map(() => ContactActions.sendSuccess()),
        catchError((error: string) => of(ContactActions.sendFail({ error }))),
      ),
    ),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly contactService: ContactService,
  ) { }
}
