import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as AuthActions from '@core/store/actions';
import { LoginRequest, LoginResponse } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class AuthEffects {
  public login$: any = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    switchMap((action: LoginRequest) =>
      this.authService.login(action).pipe(
        map((response: LoginResponse) => AuthActions.loginSuccess(response)),
        catchError((error: string) => of(AuthActions.loginFail({ error }))),
      ),
    ),
  ));

  public checkSessionValidity$: any = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.checkSessionValidity),
    tap(async () => {
      const isAlive: boolean = await this.authService.isSessionAlive();

      if (isAlive) {
        await this.router.navigate(['/admin']);
      }
    }),
  ), { dispatch: false });

  public logout$: any = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    switchMap(() =>
      this.authService.logout().pipe(
        map(() => AuthActions.logoutSuccess()),
        catchError((error: string) => of(AuthActions.logoutFail({ error }))),
      ),
    ),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }
}
