import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ConfigService } from '@shared/services/config/config.service';

@Injectable()
export class I18nService {
  constructor(
    public translateService: TranslateService,
    private configService: ConfigService,
  ) { }

  public init(): void {
    this.setDefaultLanguage();
  }

  public changeLanguage(requestedLang: string): void {
    if (this.canChangeToGivenLang(requestedLang)) {
      this.translateService.use(requestedLang);
      moment.locale(requestedLang);
      this.setLocaleInStorage(requestedLang);
    }
  }

  public getSelectedLang(): string {
    return this.translateService.currentLang;
  }

  public getCurrentLang(): Observable<string> {
    return this.translateService.onLangChange.pipe(
      startWith({ lang: this.translateService.currentLang, translations: {} }),
      map((event: LangChangeEvent) => event.lang),
    );
  }

  public setLocaleInStorage(locale: string): void {
    this.configService.setValue(this.configService.i18nStorageKey, locale);
  }

  public getStorageCurrentLocale(): string {
    return this.configService.getValue(this.configService.i18nStorageKey)
      || this.configService.defaultLocale || this.configService.fallbackLocale;
  }

  public getStorageCurrentLanguage(): string {
    return this.extractLangInLocale(this.getStorageCurrentLocale());
  }

  public getAvailableLanguages(): string[] {
    return this.configService.languages;
  }

  private getDefaultLanguage(): string {
    return this.extractLangInLocale(this.configService.defaultLocale);
  }

  private setDefaultLanguage(): void {
    // Set fallback lang
    const defaultLang: string = this.getStorageCurrentLanguage();
    this.translateService.setDefaultLang(defaultLang);

    // Detect browser lang and use it if available
    const browserLocale: string = this.translateService.getBrowserCultureLang();
    const browserLang: string = this.extractLangInLocale(browserLocale);

    if (this.isLangAvailable(defaultLang)) {
      this.translateService.use(defaultLang);
      moment.locale(defaultLang);
      this.setLocaleInStorage(defaultLang);
    } else {
      this.translateService.use(browserLang);
      moment.locale(browserLang);
      this.setLocaleInStorage(browserLang);
    }
  }

  private extractLangInLocale(locale: string): string {
    return locale.split('-')[0].toLowerCase();
  }

  private canChangeToGivenLang(requestedLang: string): boolean {
    return this.isLangAvailable(requestedLang) && this.translateService.currentLang !== requestedLang;
  }

  private isLangAvailable(requestedLang: string): boolean {
    return this.configService.languages.includes(requestedLang);
  }
}
