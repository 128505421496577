<div class="row" [ngStyle]="rowStyle">
  <div class="row__columns" [ngStyle]="colWrapperStyle">
    <ng-container *ngFor="let column of columns; let index = index; trackBy:identifyColumn">
      <div class="columns__column" [ngStyle]="columnStyle(column, index)">
        <ng-container *ngFor="let widget of column.children; trackBy:identifyWidget">
          <shared-item [ngStyle]="widgetStyle(widget)" [widget]="widget"></shared-item>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>