import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { ClesdefaLogoComponent } from './clesdefa-logo/clesdefa-logo.component';

import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { LayoutComponent } from './layout/layout/layout.component';

import { ItemComponent } from './item/item.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { SeparatorComponent } from './contents/separator/separator.component';
import { TextComponent } from './contents/text/text.component';
import { QuotesComponent } from './contents/quotes/quotes.component';
import { ButtonComponent } from './contents/button/button.component';
import { ArticlesComponent } from './contents/articles/articles.component';
import { TestimoniesComponent } from './contents/testimonies/testimonies.component';
import { ImageComponent } from './contents/image/image.component';
import { CircleBlockComponent } from './contents/circle-block/circle-block.component';
import { ContactComponent } from './contents/contact/contact.component';
import { ProfilePictureComponent } from './contents/profile-picture/profile-picture.component';
import { ContactFormContentComponent } from './contents/contact-form/contact-form.component';
import { CardComponent } from './contents/card/card.component';

import { CarouselComponent } from './containers/carousel/carousel.component';
import { RowComponent } from './containers/row/row.component';
import { PageComponent } from './containers/page/page.component';
import { loaderComponents } from './loaders';
import { pageComponents } from './pages';

export const sharedComponents: any[] = [
  ...loaderComponents,
  ...pageComponents,
  SvgIconComponent,
  ClesdefaLogoComponent,
  FooterComponent,
  MenuComponent,
  LayoutComponent,
  ItemComponent,
  ContactFormComponent,
  SeparatorComponent,
  TextComponent,
  QuotesComponent,
  ButtonComponent,
  ArticlesComponent,
  TestimoniesComponent,
  ImageComponent,
  CircleBlockComponent,
  ContactComponent,
  ProfilePictureComponent,
  ContactFormContentComponent,
  CardComponent,
  RowComponent,
  PageComponent,
  CarouselComponent,
];

export * from './svg-icon/svg-icon.component';
export * from './clesdefa-logo/clesdefa-logo.component';

export * from './layout/footer/footer.component';
export * from './layout/menu/menu.component';
export * from './layout/layout/layout.component';

export * from './item/item.component';
export * from './contact-form/contact-form.component';
export * from './contents/separator/separator.component';
export * from './contents/text/text.component';
export * from './contents/quotes/quotes.component';
export * from './contents/button/button.component';
export * from './contents/articles/articles.component';
export * from './contents/testimonies/testimonies.component';
export * from './contents/image/image.component';
export * from './contents/circle-block/circle-block.component';
export * from './contents/contact/contact.component';
export * from './contents/profile-picture/profile-picture.component';
export * from './contents/contact-form/contact-form.component';

export * from './containers/row/row.component';
export * from './containers/page/page.component';
