import { createAction, props } from '@ngrx/store';

import { LoginRequest, LoginResponse } from '@core/models';

export const LOGIN: string = '[Auth] Login';
export const LOGIN_SUCCESS: string = '[Auth] Login success';
export const LOGIN_FAIL: string = '[Auth] Login fail';

export const CHECK_SESSION_VALIDITY: string = '[Auth] Check session validity';

export const LOGOUT: string = '[Auth] Logout';
export const LOGOUT_SUCCESS: string = '[Auth] Logout success';
export const LOGOUT_FAIL: string = '[Auth] Logout fail';

export const login: any = createAction(
  LOGIN,
  props<LoginRequest>(),
);

export const loginSuccess: any = createAction(
  LOGIN_SUCCESS,
  props<LoginResponse>(),
);

export const loginFail: any = createAction(
  LOGIN_FAIL,
  props<{ error: any }>(),
);

export const checkSessionValidity: any = createAction(
  CHECK_SESSION_VALIDITY,
);

export const logout: any = createAction(
  LOGOUT,
);

export const logoutSuccess: any = createAction(
  LOGOUT_SUCCESS,
);

export const logoutFail: any = createAction(
  LOGOUT_FAIL,
  props<{ error: any }>(),
);
