import { AuthService } from './auth/auth.service';
import { ContactService } from './contact/contact.service';
import { HttpService } from './http/http.service';
import { LayoutService } from './layout/layout.service';
import { PageService } from './page/page.service';

export const coreServices: any[] = [
  AuthService,
  ContactService,
  HttpService,
  LayoutService,
  PageService,
];

export * from './auth/auth.service';
export * from './contact/contact.service';
export * from './http/http.service';
export * from './layout/layout.service';
export * from './page/page.service';
