<span
  *ngFor="let item of items; trackBy:trackByFn"
  class="skeleton-loader"
  [attr.aria-label]="ariaLabel"
  aria-busy="true"
  aria-valuemin="0"
  aria-valuemax="100"
  [attr.aria-valuetext]="loadingText"
  role="progressbar"
  tabindex="0"
  [ngClass]="classes"
  [ngStyle]="style"
></span>
