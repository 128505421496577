<div class="layout">
  <div class="layout__header">
    <shared-clesdefa-logo class="header__logo"></shared-clesdefa-logo>
  </div>

  <shared-layout-menu class="layout__menu" [menu]="menu"></shared-layout-menu>

  <div class="layout__content">
    <ng-content></ng-content>
  </div>

  <shared-layout-footer class="home__footer" [menu]="menu" [links]="links"></shared-layout-footer>
</div>
