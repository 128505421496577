export enum PageEnum {
  BLOG,
  ARTICLE,
}

export const mappingPageReverse: { [key: string]: PageEnum } = {
  blog: PageEnum.BLOG,
  article: PageEnum.ARTICLE,
};

export const mappingPage: { [key in PageEnum]: string } = {
  [PageEnum.BLOG]: 'blog',
  [PageEnum.ARTICLE]: 'article',
};
