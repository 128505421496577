import { createFeatureSelector } from '@ngrx/store';

import * as AuthReducer from './auth/auth.reducer';
import * as ContactReducer from './contact/contact.reducer';
import * as LayoutReducer from './layout/layout.reducer';
import * as PageReducer from './page/page.reducer';

export interface ClesdefaState {
  auth: AuthReducer.AuthState;
  contact: ContactReducer.ContactState;
  layout: LayoutReducer.LayoutState;
  page: PageReducer.PageState;
}

export const clesdefaStoreReducers: any = {
  auth: AuthReducer.authReducer,
  contact: ContactReducer.contactReducer,
  layout: LayoutReducer.layoutReducer,
  page: PageReducer.pageReducer,
};

export const getClesdefaState: any = createFeatureSelector<ClesdefaState>('clesdefa');

export * from './auth/auth.reducer';
export * from './contact/contact.reducer';
export * from './layout/layout.reducer';
export * from './page/page.reducer';
