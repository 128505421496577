import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Page, Row } from '@core/models';
import { isPageLoading, page } from '@core/store/selectors';
import { PageEnum } from '@core/models/enums/page.enum';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss'],
})
export class DynamicComponent {
  public pageLoading$: Observable<boolean> = this.store.pipe(select(isPageLoading));

  public page$: Observable<Page> = this.store.pipe(
    select(page),
    filter(data => !!data),
  );

  public get route(): string {
    return this.router.url;
  }

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public hasRealId(current: Page): boolean {
    return current.realId in PageEnum;
  }

  public trackById(idx: number, item: Row): string {
    return item.id;
  }
}
