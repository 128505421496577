import { Component } from '@angular/core';
import { RealPageComponent } from '@shared/components/containers/real-page.component';
import { Widget, WidgetContent, WidgetEnum } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements RealPageComponent {
  public data: any;

  public separator: Widget = new Widget('separator', 0, WidgetEnum.SEPARATOR, { style: { padding: '8px 0', color: ColorsEnum.WHITE } } as WidgetContent);
}
