import { createAction, props } from '@ngrx/store';

import { Menu } from '@core/models';

export const LOAD_MENU: string = '[Layout] Load menu';
export const LOAD_MENU_SUCCESS: string = '[Layout] Load menu success';
export const LOAD_MENU_FAIL: string = '[Layout] Load menu fail';

export const LOAD_LINKS: string = '[Layout] Load links';
export const LOAD_LINKS_SUCCESS: string = '[Layout] Load links success';
export const LOAD_LINKS_FAIL: string = '[Layout] Load links fail';

export const loadMenu: any = createAction(
  LOAD_MENU,
);

export const loadMenuSuccess: any = createAction(
  LOAD_MENU_SUCCESS,
  props<{ menu: Menu[] }>(),
);

export const loadMenuFail: any = createAction(
  LOAD_MENU_FAIL,
  props<{ error: any }>(),
);

export const loadLinks: any = createAction(
  LOAD_LINKS,
);

export const loadLinksSuccess: any = createAction(
  LOAD_LINKS_SUCCESS,
  props<{ links: Menu[] }>(),
);

export const loadLinksFail: any = createAction(
  LOAD_LINKS_FAIL,
  props<{ error: any }>(),
);
