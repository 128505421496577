import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ContentComponent } from '@shared/components/contents/content.component';
import { GoogleMapsService } from '@shared/services/google-maps/google-maps.service';
import { ContactInfo, Style, Widget, WidgetContent } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements ContentComponent, OnInit, OnChanges {
  @Input() public widget: Widget;

  public apiLoaded$: Observable<boolean> = this.googleMapsService.currentApiStatus$.pipe(
    tap(() => this.loadOptions()),
  );

  public options: google.maps.MapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 16,
  };

  public markerPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' | 'stretch' = 'stretch';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' | 'space-between' = 'space-between';
  private defaultColor: ColorsEnum = ColorsEnum.BLACK;
  private defaultBackgroundColor: string = 'transparent';
  private defaultPadding: string = '0';
  private defaultMargin: string = '0 auto';
  private defaultMaxWidth: string = 'unset';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { fields: ContactInfo, displayedFields: string[] } {
    return this.contentWidget?.content as { fields: ContactInfo, displayedFields: string[] };
  }

  public get contact(): ContactInfo {
    return this.content?.fields;
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get style(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      color: this.contentStyle.color || this.defaultColor,
      backgroundColor: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      maxWidth: this.contentStyle.maxWidth || this.defaultMaxWidth,
    };
  }

  public get markerOptions(): google.maps.MarkerOptions {
    return this.googleMapsService.markerOptions;
  }

  constructor(
    private readonly googleMapsService: GoogleMapsService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.loadOptions();
    }
  }

  public ngOnInit(): void {
    this.loadOptions();
  }

  public displayField(field: string | string[]): boolean {
    if (field instanceof Array) {
      return field.some((f: string) => this.displayField(f));
    }

    return this.content.displayedFields.includes(field) && !!this.contact[field];
  }

  public goTo(link: string): void {
    window.open(link, '_blank');
  }

  private loadOptions(): void {
    this.markerPosition = {
      lat: this.contact.lat,
      lng: this.contact.lng,
    };

    this.googleMapsService.updateMarkerOptions({
      title: this.contact.company,
      position: this.markerPosition,
    });

    this.options.center = {
      ...this.options.center,
      ...this.markerPosition,
    };
  }
}
