<div class="testimonies" [ngStyle]="style">
  <div class="testimonies__carousel" *ngIf="testimonies.length">
    <shared-carousel [items]="testimonies" [navigation]="true" [autoplay]="false">
      <ng-template let-item>
        <div class="carousel__content">
          <div class="content__text" [ngStyle]="getTestimonyBlockStyle(item)">
            <h3 [ngStyle]="getTestimonyTitleStyle(item)">Témoignage</h3>
            <div [ngStyle]="getTestimonyTextStyle(item)" [innerHTML]="item.text"></div>

            <p>
              <button mat-raised-button (click)="onClick(item)">
                <span>{{ item.button }}</span>
                <shared-svg-icon class="icon" icon="button-arrow"></shared-svg-icon>
              </button>
            </p>
          </div>
        </div>
      </ng-template>
    </shared-carousel>
  </div>
</div>