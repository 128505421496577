<div class="articles" [ngStyle]="style">
  <mat-card class="example-card" *ngFor="let article of articles; trackBy:trackById">
    <img mat-card-image [src]="article.image" [alt]="article.title">

    <mat-card-header>
      <mat-card-title>{{ article.title }}</mat-card-title>
      <mat-card-subtitle>{{ article.publishedAt | date:'EEEE d MMMM y - HH:mm' }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>{{ article.summary }}</p>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button (click)="goToArticle(article)">Lire l'article</button>
    </mat-card-actions>
  </mat-card>
</div>