<div class="carousel">
  <ng-container *ngIf="navigation">
    <button mat-icon-button #prev class="carousel__prev swiper-button-prev">
      <shared-svg-icon class="icon" icon="back-arrow"></shared-svg-icon>
    </button>

    <button mat-icon-button #next class="carousel__next swiper-button-next">
      <shared-svg-icon class="icon" icon="button-arrow"></shared-svg-icon>
    </button>
  </ng-container>

  <swiper
    class="carousel__inner"
    [loop]="true"
    [autoHeight]="autoHeight"
    [navigation]="{ enable: navigation, nextEl: '.carousel__next', prevEl: '.carousel__prev' }"
    [pagination]="pagination ? { clickable: true, dynamicBullets: true } : false"
    [autoplay]="autoplay ? { delay: 3500, disableOnInteraction: false } : false"
  >
    <ng-template swiperSlide *ngFor="let item of items; let i = index; trackBy:trackByItem">
      <ng-template
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{$implicit: item}">
      </ng-template>
    </ng-template>
  </swiper>
</div>