import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';

import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Navigation, Pagination]);

@Component({
  selector: 'shared-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @ViewChild(SwiperComponent) public swiper: SwiperComponent;
  @ViewChild('prev') public prevButton: HTMLDivElement;
  @ViewChild('next') public nextButton: HTMLDivElement;

  @ContentChild(TemplateRef) public templateRef: TemplateRef<any>;

  @Input() public items: any[];
  @Input() public pagination: boolean = false;
  @Input() public navigation: boolean = false;
  @Input() public autoplay: boolean = true;
  @Input() public autoHeight: boolean = true;

  public trackByItem(idx: number, item: { id: string }): string {
    return `carousel-${item.id}-${idx}`;
  }
}
