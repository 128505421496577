import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { resetForm } from '@core/store/actions';
import { ContactFormComponent } from '@app/components/contact-form/contact-form.component';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    private readonly store: Store,
  ) {}

  public onContact(): void {
    const contactRef: MatDialogRef<ContactFormComponent> = this.dialog.open(ContactFormComponent, {
      maxWidth: '95vw',
      maxHeight: '100vh',
      disableClose: true,
    });

    contactRef.afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => this.store.dispatch(resetForm()));
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next(false);
    this.unsubscribeAll.complete();
  }
}
