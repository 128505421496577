import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-clesdefa-logo',
  templateUrl: './clesdefa-logo.component.html',
  styleUrls: ['./clesdefa-logo.component.scss'],
})
export class ClesdefaLogoComponent {
  @Input() public white: boolean;
}
