import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RecaptchaService } from '@shared/services';
import { isLoggingIn, isLoginError } from '@core/store/selectors';
import { login } from '@core/store/actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  public login$: Observable<boolean> = this.store.pipe(select(isLoggingIn));
  public error$: Observable<boolean> = this.store.pipe(select(isLoginError));
  public loginForm: FormGroup;

  public get formControls(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly recaptchaService: RecaptchaService,
    private readonly router: Router,
  ) {
    this.buildForm();
  }

  public onBack(): void {
    this.router.navigate(['/']);
  }

  public onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.recaptchaService.getToken('login').pipe(takeUntil(this.unsubscribeAll)).subscribe((token: string) => {
      this.loginForm.get('token').setValue(token);
      this.performLogin();
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next(false);
    this.unsubscribeAll.complete();
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      token: [null],
    });
  }

  private performLogin(): void {
    this.store.dispatch(login({
      ...this.loginForm.value,
    }));
  }
}
