/* eslint-disable max-len */
import { Router } from '@angular/router';

import {
  ColorsEnum,
  Column,
  ContactInfo,
  HorizontalAlignmentEnum,
  Menu,
  Page,
  PageEnum,
  Row,
  RowPatternEnum,
  VerticalAlignmentEnum,
  Widget,
  WidgetEnum,
} from '@core/models';
import {
  linkLegalNoticeMock,
  linkPrivacyPolicyMock,
  menuAboutMock,
  menuBlogMock,
  menuContactMock,
  menuExpertiseAcpMock,
  menuExpertiseCdeMock,
  menuExpertiseCicMock,
  menuExpertiseFdeMock,
  menuExpertiseMock,
} from '../menu.mock';
import { homeMock } from './home.mock';
import { legalNoticeMock } from './legal-notice.mock';
import { privacyPolicyMock } from './privacy-policy.mock';

const expertiseCic: Page = new Page('cic', [
  new Row('cic1', 0, RowPatternEnum.SIMPLE, [
    new Column('cic1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic1-1-1', 0, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', textAlign: 'center' },
        content: '<h1>LE COACHING INDIVIDUEL</h1>',
      }),
      new Widget('cic1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '0 0 40px' },
        content: null,
      }),
      new Widget('cic1-1-3', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', maxWidth: '700px', textAlign: 'center', align: 'center' },
        content: `<p>
          Peut-être que le coaching peut m’aider pour parvenir à mes objectifs, m’aider à franchir ce cap difficile, me permettre d’y voir plus clair…<br>
          Mais comment ça se passe ? Combien de séances ? Où ? Comment ?
        </p>`,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PINK, justify: 'center' }),
  new Row('cic2', 1, RowPatternEnum.SIMPLE, [
    new Column('cic2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic2-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0' },
        content: `
          <p>Le coaching, c’est avant tout un accompagnement. Le moteur en est la volonté de changement sur le plan personnel et/ou professionnel. Une dynamique est opérée par des séances régulières et basée sur des entretiens, mises en situations, exercices corporels… Bref un processus qui repose sur une relation de collaboration pour atteindre les objectifs déterminés en amont. C’est ainsi qu’il est toujours possible d’augmenter son potentiel, son niveau de performance et de passer à l’action.</p>
          <p>Je mets à votre disposition mon savoir-faire avec toutes les expériences que j’ai pu engrammer au fil du temps dans mon évolution professionnelle au service des personnes et des collaborateurs. Ce travail se poursuit et se nourrit par des formations régulières et des questionnements pour affiner davantage ma pratique.</p>
          <p>Je vous offre un espace dédié pour un travail collaboratif de qualité, qui permet l’écoute, mais aussi des mises en situation pratiques si besoin, tester de nouveaux comportements.</p>
          <p>Un espace-temps pour vous, pour créer un espace d’alliances, coconstruire un autre demain, plus adapté à vos aspirations présentes. Ainsi être aligné avec ses comportements, savoirs, attitudes… créateur de progrès, de mieux-être.</p>
        `,
      }),
    ]),
  ], { align: 'stretch' }),
  new Row('cic3', 2, RowPatternEnum.SIMPLE_SIMPLE_SIMPLE, [
    new Column('cic3-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic3-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '3px' },
        content: {
          text: 'bureau',
          path: '/assets/images/bureau-1.jpg',
        },
      }),
    ]),
    new Column('cic3-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic3-2-1', 1, WidgetEnum.IMAGE, {
        style: { padding: '3px' },
        content: {
          text: 'bureau',
          path: '/assets/images/bureau-2.jpg',
        },
      }),
    ]),
    new Column('cic3-3', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic3-3-1', 2, WidgetEnum.IMAGE, {
        style: { padding: '3px' },
        content: {
          text: 'bureau',
          path: '/assets/images/bureau-3.jpg',
        },
      }),
    ]),
  ], { align: 'stretch', padding: '0px 0px 8px' }),
  new Row('cic4', 3, RowPatternEnum.SIMPLE, [
    new Column('cic4-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic4-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0' },
        content: `
          <p>Les séances de coaching peuvent aussi se dérouler par <strong>téléphone</strong> ou en <strong>visioconférence</strong>.</p>
        `,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('cic5', 4, RowPatternEnum.SIMPLE, [
    new Column('cic5-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic5-1-1', 0, WidgetEnum.BUTTON, {
        style: {
          justify: 'center',
          padding: '0',
        },
        content: { text: 'je souhaite prendre contact', path: '/contact' },
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PURPLE, margin: '30px 0 70px' }),
  new Row('cic6', 5, RowPatternEnum.SIMPLE, [
    new Column('cic6-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic6-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Ces formes d’accompagnement peuvent être complémentaires au coaching en présence et permettent de limiter les temps de déplacements.</h2>
        `,
      }),
      new Widget('cic6-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0 0 40px' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('cic7', 6, RowPatternEnum.SIMPLE, [
    new Column('cic7-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic7-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '0px' },
        content: {
          text: 'bureau',
          path: '/assets/images/cic-schema.png',
        },
      }),
    ]),
  ], { align: 'center', justify: 'center', padding: '8px 0' }),
  new Row('cic8', 7, RowPatternEnum.SIMPLE_GROW, [
    new Column('cic8-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic8-1-1', 0, WidgetEnum.CIRCLE_BLOCK, {
        style: { margin: '1rem 0', align: 'center', justify: 'center', backgroundColor: ColorsEnum.ORANGE, color: ColorsEnum.WHITE, width: '230px', fontSize: '1.6rem', lineHeight: '1.9rem', padding: '0.5rem' },
        content: 'La préparation d’entretiens d’embauche',
      }),
    ], { flexShrink: '0', width: 'auto' }),
    new Column('cic8-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic8-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 0 0 2rem' },
        content: '<p>Elle nécessite parfois une prise de recul pour se détacher de ses émotions, mettre en avant son savoir-faire/savoir être, être au clair sur son objectif d’emploi… Connaitre aussi ses limites, mettre en exergue ses questionnements pour la prise de poste… C’est un court accompagnement (environ 3 séances d’une heure) avec des mises en situation pour faciliter la prise de parole, parler de soi, de ses compétences, de se mettre en valeur.</p>',
      }),
    ]),
  ], { align: 'center', justify: 'flex-start', padding: '8px 0' }),
  new Row('cic11', 10, RowPatternEnum.SIMPLE_GROW, [
    new Column('cic11-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic11-1-1', 0, WidgetEnum.CIRCLE_BLOCK, {
        style: { margin: '1rem 0', align: 'center', justify: 'center', backgroundColor: ColorsEnum.PINK, color: ColorsEnum.WHITE, width: '230px', fontSize: '1.6rem', lineHeight: '1.9rem', padding: '0.5rem' },
        content: 'La reprise d’activité professionnelle',
      }),
    ], { flexShrink: '0', width: 'auto' }),
    new Column('cic11-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic11-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 0 0 2rem' },
        content: '<p>Quel que soit le motif de l’arrêt, l’idée d’une reprise peut être très insécure et mérite un travail particulier pour reprendre ses marques professionnelles, reprendre confiance en soi et pour se projeter vers ce retour… Afin d’éviter une éventuelle rechute. Un minimum de 5 séances d’une heure trente est préconisé.</p>',
      }),
    ]),
  ], { align: 'center', justify: 'flex-start', padding: '8px 0' }),
  new Row('cic9', 8, RowPatternEnum.SIMPLE_GROW, [
    new Column('cic9-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic9-1-1', 0, WidgetEnum.CIRCLE_BLOCK, {
        style: { margin: '1rem 0', align: 'center', justify: 'center', backgroundColor: ColorsEnum.PURPLE, color: ColorsEnum.WHITE, width: '230px', fontSize: '1.6rem', lineHeight: '1.9rem', padding: '0.5rem' },
        content: 'L’amélioration de ses compétences',
      }),
    ], { flexShrink: '0', width: 'auto' }),
    new Column('cic9-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic9-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 0 0 2rem' },
        content: '<p>Elle a pour objectif de mieux vivre son travail au quotidien. Quel que soit le niveau hiérarchique, le coaching sera adapté pour une organisation optimale (gestion du temps, planning…), prise de poste, pilotage d’un projet, développement de ses capacités managériales, relationnelles et décisionnelles. Cet accompagnement nécessite des temps de prises de conscience et d’élaboration d’une nouvelle stratégie. Un minimum de 8 séances d’une heure trente à 2heures est requis.</p>',
      }),
    ]),
  ], { align: 'center', justify: 'flex-start', padding: '8px 0' }),
  new Row('cic10', 9, RowPatternEnum.SIMPLE_GROW, [
    new Column('cic10-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic10-1-1', 0, WidgetEnum.CIRCLE_BLOCK, {
        style: { margin: '1rem 0', align: 'center', justify: 'center', backgroundColor: ColorsEnum.BLUE, color: ColorsEnum.WHITE, width: '230px', fontSize: '1.6rem', lineHeight: '1.9rem', padding: '0.5rem' },
        content: 'La reconversion professionnelle',
      }),
    ], { flexShrink: '0', width: 'auto' }),
    new Column('cic10-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic10-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 0 0 2rem' },
        content: '<p>C’est un moment transitoire d’activité professionnelle. Le coaching permettra de faire le point sur une activité professionnelle subie ou choisie. Il viendra interroger la personne dans sa réalité d’aujourd’hui, avec ses valeurs, ses besoins (peut-être nouveaux), ses possibles. C’est le temps de la mise au clair, du rêve qui pourra devenir une autre réalité. Ça peut aussi être une étape précédant un bilan individuel de compétence. Selon les objectifs fixés ensemble, un minimum de 6 séances de 1heure trente à deux heures est requis.</p>',
      }),
    ]),
  ], { align: 'center', justify: 'flex-start', padding: '8px 0' }),

  new Row('cic12', 11, RowPatternEnum.SIMPLE, [
    new Column('cic12-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cic12-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Partenaires</h2>
        `,
      }),
      new Widget('cic12-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('cic13', 12, RowPatternEnum.SIMPLE, [
    new Column('cic13-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cic13-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', maxWidth: '300px', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-ecf.png',
        },
      }),
    ]),
  ], { padding: '16px 8px', align: 'stretch' }),
], menuExpertiseCicMock);

const expertiseAcp: Page = new Page('acp', [
  new Row('acp1', 0, RowPatternEnum.SIMPLE, [
    new Column('acp1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp1-1-1', 0, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', textAlign: 'center' },
        content: '<h1>ANIMATION DE CODÉVELOPPEMENT PROFESSIONNEL</h1>',
      }),
      new Widget('acp1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '0 0 40px' },
        content: null,
      }),
      new Widget('acp1-1-3', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', maxWidth: '700px', textAlign: 'center', align: 'center' },
        content: `<p>
          Le codéveloppement professionnel est bien plus qu’une méthode !<br>
          C’est un carrefour de plusieurs techniques (coaching, formation, tutorat, groupe de paroles…).<br>
          C’est une belle expérience de groupe, nourrit de bienveillance, une belle dynamique individuelle et de groupe s’installe.
        </p>`,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PINK, justify: 'center' }),
  new Row('acp2', 1, RowPatternEnum.SIMPLE, [
    new Column('acp2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp2-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Le codéveloppement professionnel est bien plus qu’une méthode !</p>
          <p>Il invite, en un temps organisé, une meilleure cohésion d’équipe, à prendre soin des individus, à une meilleure qualité d’écoute, à apprendre à poser des questions ouvertes et puissantes…. Dans un contexte de bienveillance, stimulant et apprenant.</p>
          <p>Participer à un groupe de codéveloppement peut être assimilé à une formation, car sa forme particulière privilégie l’apprentissage par l’action, mise sur le groupe et sur les interactions entre les participants et il met l’accent sur le partage d’expériences et sur la réflexion individuelle et collective.</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('acp3', 2, RowPatternEnum.SIMPLE, [
    new Column('acp3-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp3-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Comment ?</h3>
          <p>Le travail effectué, individuellement et en groupe, est favorisé par un exercice de consultation qui porte sur des problématiques vécues actuellement par les participants...</p>
          <p>Participer à une séance de codéveloppement, c’est tenir tour à tour, les rôles d’apprenant d’une technique reproductible, de client puis de consultant.</p>
          <p><em>« Le groupe de codéveloppement professionnel est une approche de développement pour des personnes qui croient pouvoir apprendre les unes des autres afin d’améliorer, de consolider leur pratique. Un groupe de codéveloppement est un groupe de personnes qui s’entraident dans leur auto développement respectif. »</em></p>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp4', 3, RowPatternEnum.SIMPLE, [
    new Column('acp4-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp4-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Quels sujets aborder ?</h3>
          <p>Le sujet est discuté en amont avec l’animateur… Tous les sujets sont recevables à partir du moment où ils sont réellement incarnés par le client, qu’il représente une réelle source de préoccupation.</p>
          <p>Ça peut être un projet professionnel : reconversion, orientation différente, projet d’équipe à conduire…), ou personnel (départ à la retraite, projet de changement de vie…).</p>
          <p>Ca peut-être pour résoudre une difficulté de l’ordre de conflits ou de communication…</p>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp5', 4, RowPatternEnum.SIMPLE, [
    new Column('acp5-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp5-1-1', 1, WidgetEnum.BUTTON, {
        style: { justify: 'center', padding: '0' },
        content: { text: 'je souhaite prendre contact', path: '/contact' },
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PURPLE, margin: '30px 0 70px' }),
  new Row('acp6', 5, RowPatternEnum.SIMPLE, [
    new Column('acp6-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp6-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Une démarche en 6 étapes avec des durées différentes</h2>
        `,
      }),
      new Widget('acp6-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0 0 40px' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('acp7', 6, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('acp7-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp7-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '2rem', maxWidth: '100%' },
        content: {
          text: 'bureau',
          path: '/assets/images/acp-schema-1.png',
        },
      }),
    ]),
    new Column('acp7-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp7-1-1', 0, WidgetEnum.CARD, {
        style: { textAlign: 'center', align: 'center', justify: 'center', padding: '32px 32px 20px' },
        content: `
          <p>Une séance dure entre 1 h 30 et 2 h (selon les participants)</p>
          <p>Une étape 0 qui permet de préparer la séance, de vérifier le cadre de la demande du client</p>
        `,
      }),
    ]),
  ], { align: 'center', padding: '8px 0' }),
  new Row('acp8', 7, RowPatternEnum.SIMPLE, [
    new Column('acp8-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp8-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Mon rôle d’animatrice garantit le bon fonctionnement de la séance dans son déroulé et dans la qualité des échanges. Des apports peuvent venir soutenir la pratique de chacun (les questions ouvertes et puissantes, les différents champs d’investigation…)</p>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp9', 8, RowPatternEnum.SIMPLE, [
    new Column('acp9-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp9-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', maxWidth: '600px' },
        content: {
          text: 'bureau',
          path: '/assets/images/acp-schema-2.png',
        },
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp10', 9, RowPatternEnum.SIMPLE, [
    new Column('acp10-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp10-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Le client est au centre de l’attention, il bénéficie de la confiance du groupe pour s’exprimer, préciser son positionnement et faire un pas de côté.</p>
          <p>Les consultants sont protecteurs, bienveillants et apportent leur expertise d’usage et de culture.</p>
          <br>
          <h3>Conditions préalables pour un groupe de codéveloppement professionnel en visioconférence ou présence</h3>
          <ul>
            <li><p>Les participants doivent être motivés à améliorer leur pratique professionnelle</p></li>
            <li><p>Le groupe est constitué de 5 à 8 personnes qui s’engagent dans la durée</p></li>
            <li><p>Un rythme d’environ d’une séance par mois.</p></li>
            <li><p>Un client différent à chaque rencontre avec une problématique ou un projet à soumettre à l’expertise du groupe</p></li>
          </ul>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp11', 10, RowPatternEnum.SIMPLE, [
    new Column('acp11-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp11-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Les 3 valeurs de base</h2>
        `,
      }),
      new Widget('acp11-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('acp12', 11, RowPatternEnum.SIMPLE, [
    new Column('acp12-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp12-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem 0', maxWidth: '900px' },
        content: {
          text: 'bureau',
          path: '/assets/images/acp-schema-3.png',
        },
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp13', 12, RowPatternEnum.SIMPLE, [
    new Column('acp13-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp13-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Pour qui ?</h3>
          <p>J’organise des groupes de codéveloppement professionnel avec des personnes (qui ne se connaissent pas) en processus de changement et qui souhaitent prendre le temps de maturer et partager un nouveau projet professionnel</p>
          <p>Le groupe peut aussi être constitué de pairs, dans le cadre de leur travail qui souhaitent prendre un temps singulier pour analyser des situations professionnelles (problématique ou projet)</p>
          <p>Le groupe de pair peut aussi être constitué à partir d’une même situation : autour des questions de parentalité, du handicap, de formation en alternance…</p>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp14', 13, RowPatternEnum.SIMPLE, [
    new Column('acp14-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp14-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Pour quoi ?</h3>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp15', 14, RowPatternEnum.SIMPLE, [
    new Column('acp15-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('acp15-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem 0' },
        content: {
          text: 'bureau',
          path: '/assets/images/acp-schema-4.png',
        },
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('acp16', 15, RowPatternEnum.SIMPLE, [
    new Column('acp16-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp16-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Partenaires</h2>
        `,
      }),
      new Widget('acp16-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('acp17', 16, RowPatternEnum.SIMPLE_GROW, [
    new Column('acp17-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp17-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem 0', maxWidth: '300px' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-aqcp.png',
        },
      }),
    ]),
    new Column('acp17-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('acp17-2-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>je suis membre et je participe régulièrement à des évènements pour me perfectionner dans ma pratique d’animation.</h3>
        `,
      }),
    ]),
  ], { padding: '16px 8px 32px' }),
], menuExpertiseAcpMock);

const expertiseFde: Page = new Page('fde', [
  new Row('fde1', 0, RowPatternEnum.SIMPLE, [
    new Column('fde1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde1-1-1', 0, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', textAlign: 'center' },
        content: '<h1>FORMATION DANS LE DOMAINE ÉDUCATIF</h1>',
      }),
      new Widget('fde1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '0 0 40px' },
        content: null,
      }),
      new Widget('fde1-1-3', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', maxWidth: '700px', textAlign: 'center', align: 'center' },
        content: `<p>
          La laïcité s’invite largement dans l’espace public et au quotidien dans le champ de l’éducation ou de la gestion d’une commune. 
          Ce concept peut paraitre flou et suscite le plus souvent des réactions où l’émotion l’emporte sur la raison. <br>
          Prendre du recul, étudier les situations qui peuvent se présenter au regard de la législation et du droit. 
          Elle vise à encourager le vivre ensemble de la dynamique républicaine.
        </p>`,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PINK, justify: 'center' }),
  new Row('fde2', 1, RowPatternEnum.SIMPLE, [
    new Column('fde2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('fde2-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Depuis 2016, je suis formatrice sur la question des valeurs de la République et la Laïcité.</p>
          <p>J’ai pu intégrer le plan de formation national organisé par l’État (agence nationale pour la cohésion des territoires - ANCT) dans le but de former les acteurs de terrain sur cette question sociétale qui fait fréquemment l’actualité.</p>
          <p>L’engagement pris est celui de réaliser au minimum 2 formations de 1 ou 2 jours pour un public élargi : agents ou salariés travailleurs sociaux, au contact du public, dans les quartiers sensibles… mais aussi les élus municipaux et/ou associatifs. Ces formations sont gratuites pour les stagiaires.</p>
          <p>Je travaille régulièrement avec le CNFPT (centre national fonction publique territoriale) qui est chargé de la formation des agents territoriaux. L’association AlterEgaux.Isère est également partenaire de ces formations afin de l’ouvrir au secteur associatif.</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde3', 2, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('fde3-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde3-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '300px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-laicite.png',
        },
      }),
      new Widget('fde3-1-2', 1, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Expression Laïcité</p>
        `,
      }),
    ]),
    new Column('fde3-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde3-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '300px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-republique.png',
        },
      }),
      new Widget('fde3-2-2', 1, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Expression République</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde4', 3, RowPatternEnum.SIMPLE, [
    new Column('fde4-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('fde4-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>L’association Éducation République Égalité est également un partenaire dans ses formations.</p>
          <p>Nous pouvons intervenir auprès de vos équipes, sur demandes.</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde5', 4, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('fde5-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde5-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '0', height: 'auto', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-valeur-republique-1.png',
        },
      }),
    ]),
    new Column('fde5-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde5-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '0', height: 'auto', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-valeur-republique-2.png',
        },
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde6', 5, RowPatternEnum.SIMPLE, [
    new Column('fde6-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('fde6-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Si vous êtes intéressé pour vous ou vos équipes, n’hésitez pas à me contacter.</p>
          <p>Des formations de formateur sont aussi possibles, elles se déroulent sur 3 jours</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde7', 6, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('fde7-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde7-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '0', height: 'auto', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-valeur-republique-3.png',
        },
      }),
    ]),
    new Column('fde7-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde7-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '0', height: 'auto', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/formation-valeur-republique-4.png',
        },
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('fde8', 7, RowPatternEnum.SIMPLE, [
    new Column('fde8-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('fde8-1-1', 0, WidgetEnum.BUTTON, {
        style: {
          justify: 'center',
          padding: '0',
        },
        content: { text: 'je souhaite prendre contact', path: '/contact' },
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PURPLE, margin: '30px 0 70px' }),
  new Row('fde9', 8, RowPatternEnum.SIMPLE, [
    new Column('fde9-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('fde9-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Partenaires</h2>
        `,
      }),
      new Widget('fde9-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('fde10', 9, RowPatternEnum.SIMPLE_SIMPLE_SIMPLE, [
    new Column('fde10-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde10-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-ere.png',
        },
      }),
    ]),
    new Column('fde10-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde10-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-aei.png',
        },
      }),
    ]),
    new Column('fde10-4', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde10-4-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-cnfpt.png',
        },
      }),
    ]),
  ], { padding: '16px 8px 8px', align: 'stretch' }),
  new Row('fde11', 10, RowPatternEnum.SIMPLE_SIMPLE, [
    new Column('fde11-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde11-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-vrl.png',
        },
      }),
    ]),
    new Column('fde11-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('fde11-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-anct.png',
        },
      }),
    ]),
  ], { padding: '8px 8px 16px', align: 'stretch' }),
], menuExpertiseFdeMock);

const expertiseCde: Page = new Page('cde', [
  new Row('cde1', 0, RowPatternEnum.SIMPLE, [
    new Column('cde1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde1-1-1', 0, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', textAlign: 'center' },
        content: '<h1>CONSULTATION DANS LE DOMAINE ÉDUCATIF</h1>',
      }),
      new Widget('cde1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '0 0 40px' },
        content: null,
      }),
      new Widget('cde1-1-3', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.WHITE, padding: '0', maxWidth: '700px', textAlign: 'center', align: 'center' },
        content: `<p>
          La dynamique d’équipe ne s’impose pas, elle se construit autour d’un projet partagé.<br>
          Elle s’entretient aussi car avec le temps, elle peut se déliter. 
          La ludopédagogie, les outils participatifs permettent à chacun de trouver son espace au sein de l’équipe et de valoriser les personnalités…
        </p>`,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PINK, justify: 'center' }),
  new Row('cde2', 1, RowPatternEnum.SIMPLE, [
    new Column('cde2-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cde2-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <p>Mes d’interventions reposent sur le respect des personnes et du groupe : un accord de groupe est établi lors de la première rencontre.</p>
          <p>J’utilise des méthodes actives et dynamiques (ludopédagogie) pour favoriser la participation, avec des supports tels que cartes, jeux, vidéos et jeux de rôle… Les outils participatifs ont pour rôle de mobiliser l’intelligence collective, l’écoute active et la communication non-violente.</p>
          <p>Mes interventions prennent appui sur la participation active des personnes avec une phase réflexive (individuelle) puis des mises en commun par un travail collaboratif pour s’enrichir les réflexions de chacun.</p>
          <p>Des mises en situations peuvent servir de point d’appui pour mieux prendre de la distance, faire un pas de côté pour analyser ce qui s’est passé et ajuster les postures professionnelles d’équipe.</p>
          <p>Toutes les séances sont construites avec des outils que chaque participant peut réutiliser dans sa pratique, aussi bien avec des enfants qu’avec des adultes. Des fiches « ateliers » peuvent être transmises.</p>
          <p>Ma connaissance et mon expertise dans le domaine éducatif du temps périscolaire et du métier d’ATSEM sont aussi des atouts pour partager d’autres pratiques professionnelles, des mises en lien entre structures peuvent s’envisager. Des apports pédagogiques théoriques peuvent également venir étayer la compréhension de la situation évoquée (par exemple les neurosciences pour mieux comprendre le fonctionnement des émotions).</p>
          <p>Des apports théoriques et bibliographies donnent des clés pour aller plus loin.</p>
        `,
      }),
    ]),
  ], { padding: '32px 8px 16px' }),
  new Row('cde3', 2, RowPatternEnum.SIMPLE, [
    new Column('cde3-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cde3-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Pour qui ?</h3>
          <ul>
            <li><p>Les collectivités territoriales (services petite-enfance, enfance, jeunesse…)</p></li>
            <li><p>Les structures associatives (MJC, centres sociaux, maisons des habitants…)</p></li>
            <li><p>Les équipes professionnelles périscolaires, ATSEM, de direction… d’une même collectivité ou des groupes de pairs pour un partage de pratique)</p></li>
            <li><p>Des équipes pour faciliter un travail sur le projet associatif, construire des valeurs communes et leur mise en action…</p></li>
          </ul>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('cde4', 3, RowPatternEnum.SIMPLE, [
    new Column('cde4-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cde4-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0px' },
        content: `
          <h3>Quelle durée ?</h3>
          <p>Selon la demande qui peut être d’une journée, de séances régulières de 2 h/mois sur une année scolaire, de plusieurs journées/ mois… à la carte selon le projet final.</p>
          <p><em>Contactez-moi pour étudier ensemble vos besoins</em></p>
        `,
      }),
    ]),
  ], { padding: '16px 8px' }),
  new Row('cde5', 4, RowPatternEnum.SIMPLE, [
    new Column('cde5-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cde5-1-1', 0, WidgetEnum.BUTTON, {
        style: {
          justify: 'center',
          padding: '0',
        },
        content: { text: 'je souhaite prendre contact', path: '/contact' },
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PURPLE, margin: '30px 0 70px' }),
  new Row('cde6', 5, RowPatternEnum.SIMPLE, [
    new Column('cde6-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('cde6-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0', textAlign: 'center', color: ColorsEnum.PURPLE },
        content: `
          <h2>Partenaires</h2>
        `,
      }),
      new Widget('cde6-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.PINK, padding: '0' },
        content: null,
      }),
    ]),
  ], { align: 'flex-start', padding: '8px 0' }),
  new Row('cde7', 6, RowPatternEnum.SIMPLE_SIMPLE_SIMPLE, [
    new Column('cde7-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde7-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-caf.png',
        },
      }),
    ]),
    new Column('cde7-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde7-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-grenoble.png',
        },
      }),
    ]),
    new Column('cde7-3', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde7-3-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-crolles.png',
        },
      }),
    ]),
  ], { padding: '16px 8px', align: 'stretch' }),
  new Row('cde8', 7, RowPatternEnum.SIMPLE_SIMPLE_SIMPLE, [
    new Column('cde8-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde8-1-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-mjc-abrets.png',
        },
      }),
    ]),
    new Column('cde8-2', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde8-2-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', maxWidth: '300px', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-mjc-rives.png',
        },
      }),
    ]),
    new Column('cde8-3', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('cde8-3-1', 0, WidgetEnum.IMAGE, {
        style: { padding: '1rem', height: '150px', width: 'auto', objectFit: 'contain' },
        content: {
          text: 'bureau',
          path: '/assets/images/logo-chatel.png',
        },
      }),
    ]),
  ], { padding: '16px 8px 32px', align: 'stretch' }),
], menuExpertiseCdeMock);

// TEMP
const expertise: Page = new Page('expertise', null, menuExpertiseMock);

const blog: Page = new Page('blog', null, menuBlogMock, PageEnum.BLOG);

const about: Page = new Page('about', [
  new Row('about1', 0, RowPatternEnum.SIMPLE, [
    new Column('about1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about1-1-1', 1, WidgetEnum.PROFILE_PICTURE, {
        style: { padding: '30px 30px 0' },
        content: {
          text: 'Anne FAVIER',
          path: '/assets/images/anne-favier.png',
        },
      }),
      new Widget('about1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { backgroundColor: 'transparent' },
        content: null,
      }),
      new Widget('about1-1-3', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.ORANGE, padding: '30px 0 0' },
        content: '<h1>Anne FAVIER</h1>',
      }),
      new Widget('about1-1-4', 2, WidgetEnum.TEXT, {
        style: { color: ColorsEnum.BLUE, padding: '0' },
        content: '<h2>Clés de Fa, c’est la subtile combinaison de mes initiales et d’un parcours de vie à transmettre</h2>',
      }),
    ]),
  ], {}),
  new Row('about2', 0, RowPatternEnum.DOUBLE_SIMPLE, [
    new Column('about2-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about2-1-1', 1, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: `
        <p>
          Une appétence particulière pour la motricité du jeune enfant, m’a conduit vers la prévention et la relation parents/enfants. J’ai pu dans ce cadre organiser des formations en direction des parents et professionnels. 
          <br>En occupant un poste de directrice de crèche associative et parentale, j’ai perfectionné ma notion de management d’équipe associative avec l’outil de l’analyse transactionnelle en prenant en compte les professionnels et les administrateurs, travailler la notion de projets, de processus de changement notamment avec l’agrandissement de la structure et le passage de 8 à 20 salariés.
          <br>La question des modèles des organisations est alors apparue comme une évidence à questionner et approfondir.
        </p>
        <p>Un engagement politique avec un mandat d’adjointe au maire chargée de l’enfance et l’éducation, et au sein de la communauté d’agglomération sur les questions de la politique de la ville et la jeunesse, m’ont permis d’élargir mon champ de compétence et mieux connaitre la fonction publique territoriale ainsi que le rôle des élus.</p>
        <p>C’est ainsi que j’ai pu aborder une démarche globale et cohérente avec l’ensemble de la communauté éducative et la mise en place d’une démarche participative avec les outils de la sociocratie.</p>
      `,
      }),
    ]),
    new Column('about2-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about1-1-1', 2, WidgetEnum.CIRCLE_BLOCK, {
        style: { margin: '1rem 0', align: 'center', justify: 'center', backgroundColor: ColorsEnum.PINK, color: ColorsEnum.WHITE, width: '230px', fontSize: '1.2rem', lineHeight: '1.6rem', padding: '0.5rem' },
        content: 'Clés de Fa est l’aboutissement d’un parcours professionnel et personnel, qui se poursuit encore…',
      }),
    ], { width: 'auto', display: 'inline-flex', flexShrink: '0' }),
  ], { align: 'flex-start' }),
  new Row('about3', 1, RowPatternEnum.SIMPLE, [
    new Column('about3-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about3-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: '<h3>Clés de Fa est la partition qui se joue lors des accompagnements à deux ou en groupe</h3>',
      }),
      new Widget('about3-1-2', 1, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: `
        <p>Avec un diplôme de psychomotricienne, je suis passionnée pour la corrélation entre le travail corporel et ses bienfaits sur le psychisme. J’ai pu approfondir différentes techniques de relaxation, méditation… et/ou, à l’inverse, mettre toute l’énergie corporelle au service d’une action, d’un besoin.</p>
      `,
      }),
    ]),
  ], {}),
  new Row('about4', 2, RowPatternEnum.SIMPLE, [
    new Column('about4-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about4-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: '<h3>Clés de Fa symbolise toutes les clés qui peuvent être mises à votre disposition pour vous aider à mieux vous connaître et vivre les changements qui se profilent</h3>',
      }),
      new Widget('about4-1-2', 1, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: `
        <p>Mon approche de la fonction publique s’est approfondie par un stage d’étude au Centre de gestion de la fonction publique territoriale de l’Isère (CDG38) dans le cadre d’une reprise d’étude pour l’obtention d’un Master 2 « ressources humaines, organisation et conduite de changement ».</p>
        <p>En tant que chargée de mission écoles et territoires au CDG38, mes interventions à l’échelle départementale ont consisté à apporter un soutien aux communes et intercommunalités, en partenariat avec les acteurs du territoire afin d’élaborer une approche globale et cohérente de l’éducation.</p>
        <p>L’offre de service est adaptée aux communes, répondant aux inquiétudes soit des professionnels, soit des élus, et en apportant des réponses en matière de ressources humaines, gestion des conflits et bien-être au travail (apport d’expertises et animations dans une démarche participative auprès de la communauté éducative, organisation de formations pour accompagner la réalisation du projet éducatif de territoire).</p>
      `,
      }),
    ]),
  ], {}),
  new Row('about5', 2, RowPatternEnum.SIMPLE, [
    new Column('about5-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('about5-1-1', 0, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: '<h3>Aujourd’hui Clés de Fa : Facilitatrice de changement</h3>',
      }),
      new Widget('about5-1-2', 1, WidgetEnum.TEXT, {
        style: { padding: '0 1rem' },
        content: `
        <p>Avec les outils du coaching individuel (certification délivrée par l’école de coaching francophone), la PNL (programmation neuro linguistique - Paul Pyronnet Institut), l’ennéagramme (praticien en Ennéagramme Envolutif ®) mais aussi l’analyse transactionnelle et la communication non violente font partis de mes outils.</p>
        <p>Je prends soin de vous et prends beaucoup de plaisir à vous permettre d’avancer.</p>
      `,
      }),
    ]),
  ], {}),
], menuAboutMock);

const contact: Page = new Page('contact', [
  new Row('contact1', 0, RowPatternEnum.SIMPLE, [
    new Column('contact1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('contact1-1-1', 1, WidgetEnum.TEXT, {
        style: { textAlign: 'center', color: ColorsEnum.WHITE, padding: '0' },
        content: '<p>Vous souhaitez en savoir plus, une question ? <br/> Remplissez le formulaire suivant</p>',
      }),
      new Widget('contact1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '10px 0 0' },
        content: null,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.PURPLE }),
  new Row('contact2', 1, RowPatternEnum.SIMPLE, [
    new Column('contact2-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('contact2-1-1', 1, WidgetEnum.CONTACT_FORM, {
        style: { width: '100%', maxWidth: '850px', padding: '0', margin: '0 auto' },
        content: {
          fields: [],
          subjects: [],
        },
      }),
    ]),
  ], { padding: '30px' }),
  new Row('contact3', 2, RowPatternEnum.SIMPLE, [
    new Column('contact3-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('123', 1, WidgetEnum.CONTACT, {
        style: { width: '100%', padding: '0', maxWidth: '850px' },
        content: {
          fields: new ContactInfo(),
          displayedFields: [
            'name',
            'company',
            'phone',
            'email',
            'address',
            'facebook',
            'linkedIn',
          ],
        },
      }),
    ]),
  ], { padding: '0 30px 30px' }),
], menuContactMock);

const legalNotice: Page = new Page('legalNotice', [
  new Row('legal1', 0, RowPatternEnum.SIMPLE, [
    new Column('legal1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('legal1-1-1', 1, WidgetEnum.TEXT, {
        style: { textAlign: 'center', color: ColorsEnum.WHITE, padding: '0' },
        content: '<h1>MENTIONS LÉGALES</h1>',
      }),
      new Widget('legal1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '10px 0 0' },
        content: null,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.ORANGE }),
  new Row('legal2', 1, RowPatternEnum.SIMPLE, [
    new Column('legal2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('legal2-1-1', 1, WidgetEnum.TEXT, {
        style: { width: '100%', padding: '0', margin: '0 auto', textAlign: 'justify' },
        content: legalNoticeMock,
      }),
    ]),
  ], { padding: '30px 10px' }),
], linkLegalNoticeMock);

const privacyPolicy: Page = new Page('privacyPolicy', [
  new Row('privacyPolicy1', 0, RowPatternEnum.SIMPLE, [
    new Column('privacyPolicy1-1', { horizontal: HorizontalAlignmentEnum.CENTER, vertical: VerticalAlignmentEnum.CENTER }, [
      new Widget('privacyPolicy1-1-1', 1, WidgetEnum.TEXT, {
        style: { textAlign: 'center', color: ColorsEnum.WHITE, padding: '0' },
        content: '<h1>POLITIQUE DE CONFIDENTIALITÉ</h1>',
      }),
      new Widget('privacyPolicy1-1-2', 1, WidgetEnum.SEPARATOR, {
        style: { color: ColorsEnum.WHITE, padding: '10px 0 0' },
        content: null,
      }),
    ]),
  ], { backgroundColor: ColorsEnum.ORANGE }),
  new Row('privacyPolicy1', 1, RowPatternEnum.SIMPLE, [
    new Column('privacyPolicy1-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('privacyPolicy1-1-1', 1, WidgetEnum.TEXT, {
        style: { width: '100%', padding: '0', margin: '0 auto', textAlign: 'justify' },
        content: privacyPolicyMock,
      }),
    ]),
  ], { padding: '30px 10px' }),

  new Row('privacyPolicy2', 1, RowPatternEnum.SIMPLE, [
    new Column('privacyPolicy2-1', { horizontal: HorizontalAlignmentEnum.LEFT, vertical: VerticalAlignmentEnum.TOP }, [
      new Widget('privacyPolicy2-1-1', 1, WidgetEnum.TEXT, {
        style: { width: '100%', padding: '0', margin: '0 auto', textAlign: 'justify' },
        content: `
          <p>
            Pour plus d'information, dirigez-vous vers l'article « 7. Gestion des données personnelles » des <a href="../legal-notice">mentions légales</a>.
          </p>
        `,
      }),
    ]),
  ], { padding: '0 10px 30px' }),
], linkPrivacyPolicyMock);

const pages: Page[] = [
  homeMock,
  expertise, // TEMP
  expertiseCic,
  expertiseAcp,
  expertiseFde,
  expertiseCde,
  blog,
  about,
  contact,
  legalNotice,
  privacyPolicy,
];

export const findPageByPath = (path: string, router: Router): Page => {
  const current: Page = pages.find((page: Page) => page.menu.path === path && !page.menu.disabled);

  if (!current) {
    router.navigate(['/']);

    return;
  }

  const menu: Menu = current.menu;

  if (menu.allowChildren && menu.children.length) {
    router.navigate(menu.children[0].path.split('/'));

    return;
  }

  return current;
};
/* eslint-enable max-len */
