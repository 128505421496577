import { Style } from '@core/models/interfaces/style.interface';

export class Quote {
  constructor(
    public id: string,
    public text: string,
    public image: string,
    public signature?: string,
    public style: Style = null,
    public disabled: boolean = false,
  ) {}
}
