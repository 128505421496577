import { ConfigService } from './config/config.service';
import { I18nService } from './i18n/i18n.service';
import { RecaptchaService } from './recaptcha/recaptcha.service';
import { GoogleMapsService } from './google-maps/google-maps.service';

export const sharedServices: any[] = [
  ConfigService,
  I18nService,
  RecaptchaService,
  GoogleMapsService,
];

export * from './config/config.service';
export * from './i18n/i18n.service';
export * from './recaptcha/recaptcha.service';
export * from './google-maps/google-maps.service';
