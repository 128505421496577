import { LoaderComponent } from './loader/loader.component';
import { HomeLoaderComponent } from './home-loader/home-loader.component';
import { ExpertiseLoaderComponent } from './expertise-loader/expertise-loader.component';
import { AboutLoaderComponent } from './about-loader/about-loader.component';
import { ContactLoaderComponent } from './contact-loader/contact-loader.component';
import { BigPageLoaderComponent } from './big-page-loader/big-page-loader.component';

export const loaderComponents: any[] = [
  LoaderComponent,
  HomeLoaderComponent,
  ExpertiseLoaderComponent,
  AboutLoaderComponent,
  ContactLoaderComponent,
  BigPageLoaderComponent,
];

export * from './loader/loader.component';
export * from './home-loader/home-loader.component';
export * from './expertise-loader/expertise-loader.component';
export * from './about-loader/about-loader.component';
export * from './contact-loader/contact-loader.component';
export * from './big-page-loader/big-page-loader.component';
