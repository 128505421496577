import { createAction, props } from '@ngrx/store';

import { Page } from '@core/models';

export const LOAD_PAGE: string = '[Page] Load page';
export const LOAD_PAGE_SUCCESS: string = '[Page] Load page success';
export const LOAD_PAGE_FAIL: string = '[Page] Load page fail';

export const loadPage: any = createAction(
  LOAD_PAGE,
  props<{ path: string }>(),
);

export const loadPageSuccess: any = createAction(
  LOAD_PAGE_SUCCESS,
  props<{ page: Page }>(),
);

export const loadPageFail: any = createAction(
  LOAD_PAGE_FAIL,
  props<{ error: any }>(),
);
