import { AuthEffects } from './auth/auth.effect';
import { ContactEffects } from './contact/contact.effect';
import { LayoutEffects } from './layout/layout.effect';
import { PageEffects } from './page/page.effect';

export const clesdefaStoreEffects: any[] = [
  AuthEffects,
  ContactEffects,
  LayoutEffects,
  PageEffects,
];

export * from './auth/auth.effect';
export * from './contact/contact.effect';
export * from './layout/layout.effect';
export * from './page/page.effect';
