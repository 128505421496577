import { Component, Input } from '@angular/core';

import { Column, Row, Style, Widget } from '@core/models';
import { mappingHorizontalAlignment } from '@core/models/enums/horizontal-alignment.enum';
import { mappingVerticalAlignment } from '@core/models/enums/vertical-alignment.enum';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent {
  @Input() public row: Row;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultColor: ColorsEnum = ColorsEnum.BLACK;
  private defaultBackgroundColor: string = 'transparent';
  private defaultPadding: string = '30px 0';
  private defaultMargin: string = '0';
  private defaultTextAlign: string = 'left';

  public get columns(): Column[] {
    return this.row?.children;
  }

  public get contentStyle(): Style {
    return this.row?.style;
  }

  public get rowStyle(): { [key: string]: string } {
    return {
      backgroundColor: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
      color: this.contentStyle.color || this.defaultColor,
    };
  }

  public get colWrapperStyle(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      textAlign: this.contentStyle.textAlign || this.defaultTextAlign,
    };
  }

  public columnStyle(column: Column, index: number): { [key: string]: string } {
    const pattern: any = this.row.pattern.split('-');

    return {
      alignItems: mappingHorizontalAlignment[column.alignment.horizontal],
      justifyContent: mappingVerticalAlignment[column.alignment.vertical],
      width: column.style?.width || '100%',
      flexGrow: pattern[index] || '0',
      flexBasis: column.style?.flexBasis || 'auto',
      flexShrink: column.style?.flexShrink || '1',
      padding: column.style?.padding,
      backgroundColor: column.style?.backgroundColor,
    };
  }

  public widgetStyle(widget: Widget): { [key: string]: string } {
    const style: Style = widget.content.style;

    return {
      width: style.width || 'auto',
      height: style.height || 'auto',
    };
  }

  public identifyColumn(index: number, item: Column): string {
    return item.id;
  }

  public identifyWidget(index: number, item: Widget): string {
    return item.id;
  }
}
