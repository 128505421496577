import { Component, Input } from '@angular/core';
import { ContentComponent } from '@shared/components/contents/content.component';
import { Style, Widget, WidgetContent } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';

@Component({
  selector: 'shared-circle-block',
  templateUrl: './circle-block.component.html',
  styleUrls: ['./circle-block.component.scss'],
})
export class CircleBlockComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultColor: ColorsEnum = ColorsEnum.WHITE;
  private defaultBackgroundColor: ColorsEnum = ColorsEnum.ORANGE;
  private defaultPadding: string = '30px 0';
  private defaultMargin: string = '0';
  private defaultWidth: string = '300px';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): string {
    return this.contentWidget?.content as string;
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get styleBlock(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      backgroundColor: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      width: this.contentStyle.width || this.defaultWidth,
      height: this.contentStyle.width || this.defaultWidth,
    };
  }

  public get styleText(): { [key: string]: string } {
    return {
      color: this.contentStyle.color || this.defaultColor,
      fontSize: this.contentStyle.fontSize || '30px',
      fontWeight: this.contentStyle.fontWeight || '400',
      lineHeight: this.contentStyle.lineHeight || '34px',
    };
  }
}
