import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as ContactActions from '@core/store/actions';

export interface ContactState {
  loading: boolean;
  loaded: boolean;
  locked: boolean;
  error: boolean;
}

export const initialContactState: ContactState = {
  loading: false,
  loaded: false,
  locked: false,
  error: false,
};

const reducer: ActionReducer<ContactState, Action> = createReducer(
  initialContactState,
  on(ContactActions.send, (state: ContactState) => ({ ...state, loading: true, locked: true, loaded: false, error: false })),
  on(ContactActions.sendSuccess, (state: ContactState) => ({ ...state, loading: false, loaded: true, error: false })),
  on(ContactActions.sendFail, (state: ContactState) => ({ ...state, loading: false, loaded: false, locked: false, error: true })),
  on(ContactActions.resetForm, () => ({ ...initialContactState })),
);

export function contactReducer(state: ContactState | undefined, action: Action): ContactState {
  return reducer(state, action);
}
