<div class="layout-footer">
  <shared-clesdefa-logo class="layout-footer__logo" [white]="true"></shared-clesdefa-logo>

  <div class="layout-footer__social-media">
    <button *ngIf="displayField('facebook')" mat-mini-fab color="white" (click)="goTo(contact.facebook)">
      <shared-svg-icon class="icon" icon="facebook"></shared-svg-icon>
    </button>

    <button *ngIf="displayField('linkedIn')" mat-mini-fab color="white" (click)="goTo(contact.linkedIn)">
      <shared-svg-icon class="icon" icon="linked-in"></shared-svg-icon>
    </button>
  </div>

  <div class="layout-footer__menu">
    <a *ngFor="let tab of menu; trackBy:trackById" [href]="tab.path">{{ tab.name }}</a>
  </div>

  <div class="layout-footer__links">
    <a *ngFor="let tab of links; trackBy:trackById" [href]="tab.path">{{ tab.name }}</a>
  </div>
</div>
