<div class="contact-loader">
  <div class="contact-loader__form">
    <shared-skeleton-loader count="2" appearance="block" height="200px"></shared-skeleton-loader>
    <shared-skeleton-loader count="4" appearance="line"></shared-skeleton-loader>
    <shared-skeleton-loader count="1" appearance="button"></shared-skeleton-loader>
  </div>

  <div class="contact-loader__contact">
    <shared-skeleton-loader count="1" appearance="block" height="400px"></shared-skeleton-loader>
  </div>
</div>