import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as AuthActions from '@core/store/actions';

export interface LoginState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

export interface LogoutState {
  loading: boolean;
  error: boolean;
}

export interface AuthState {
  login: LoginState;
  logout: LogoutState;
}

export const initialAuthState: AuthState = {
  login: {
    loading: false,
    loaded: false,
    error: false,
  },
  logout: {
    loading: false,
    error: false,
  },
};

const reducer: ActionReducer<AuthState, Action> = createReducer(
  initialAuthState,
  on(AuthActions.login, (state: AuthState) => ({
    ...state,
    login: {
      ...state.login,
      loading: true,
      loaded: false,
      error: false,
    },
  })),
  on(AuthActions.loginSuccess, (state: AuthState) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
      loaded: true,
      error: false,
    },
  })),
  on(AuthActions.loginFail, (state: AuthState) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
      loaded: false,
      error: true,
    },
  })),
  on(AuthActions.logout, (state: AuthState) => ({
    ...state,
    logout: {
      ...state.logout,
      loading: true,
      error: false,
    },
  })),
  on(AuthActions.logoutSuccess, () => ({ ...initialAuthState })),
  on(AuthActions.logoutFail, (state: AuthState) => ({
    ...state,
    logout: {
      ...state.logout,
      loading: false,
      error: true,
    },
  })),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
