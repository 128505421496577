import { Component, Input } from '@angular/core';

import { Style, Widget, WidgetContent } from '@core/models';
import { ColorsEnum } from '@core/models/enums/colors.enum';
import { ContentComponent } from '@shared/components/contents/content.component';

@Component({
  selector: 'shared-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'center';
  private defaultColor: ColorsEnum = ColorsEnum.ORANGE;
  private defaultBackgroundColor: string = 'transparent';
  private defaultPadding: string = '30px 0';
  private defaultMargin: string = '0';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get style(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      color: this.contentStyle.color || this.defaultColor,
      backgroundColor: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
    };
  }
}
