import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from '@env';
import { ClesdefaState, clesdefaStoreReducers } from '@core/store/reducers';
import { clesdefaStoreEffects } from '@core/store/effects';
import { coreServices } from '@core/services';
import { SharedModule } from '@shared/shared.module';

import { appComponents, appDialog } from '@app/components';

import { APP_ROUTES } from '@app/app.routes';
import { AppComponent } from './main/app.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [stateSetter]
  : [];

const mutateObjectProperty = (prop, newValue, object, ignoreValues: any[] = []): any => JSON.parse(JSON.stringify(object), (key, value) => {
  if (key === prop && !ignoreValues.includes(value)) {
    return newValue;
  } else {
    return value;
  }
});

export const FEATURE_REDUCER_TOKEN: InjectionToken<ActionReducer<ClesdefaState>> = new InjectionToken<ActionReducerMap<ClesdefaState>>('Clesdefa Reducers');
export function getReducers(): ActionReducerMap<ClesdefaState> {
  return clesdefaStoreReducers;
}

const localeMapping: { [locale: string]: string } = {
  'fr-FR': 'fr',
  'en-EN': 'en',
};

const defaultLocale: string = localeMapping[environment.i18n.defaultLocale] || environment.i18n.defaultLocale;

import(
  /* webpackInclude: /((fr|en)[-\w{2,4}]*)\.mjs/ */
  /* webpackChunkName: "locale" */
  /* webpackMode: "lazy-once" */
  /* webpackExports: "default" */
  `../../node_modules/@angular/common/locales/${defaultLocale}`)
  .then((module: any) => module.default)
  .then((module: any) => registerLocaleData(module));

@NgModule({
  declarations: [
    AppComponent,
    ...appComponents,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    RecaptchaV3Module,

    SharedModule.forRoot(),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false, // environment.enableTracing,
    }),

    // Store modules
    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    StoreModule.forFeature('clesdefa', FEATURE_REDUCER_TOKEN),

    EffectsModule.forRoot([]),
    EffectsModule.forFeature(clesdefaStoreEffects),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
  ],
  providers: [
    { provide: FEATURE_REDUCER_TOKEN, useFactory: getReducers },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    { provide: LOCALE_ID, useValue: defaultLocale },
    ...coreServices,
  ],
  entryComponents: [
    ...appDialog,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (action.type === 'SET_ROOT_STATE') {
      return mutateObjectProperty('lastLoaded', Date.now(), { ...action.payload }, [0, null]);
    }

    return reducer(state, action);
  };
}
