<div class="quotes" [ngStyle]="style">
  <div class="quotes__carousel" *ngIf="quotes.length">
    <shared-carousel [items]="quotes" [pagination]="true" [autoHeight]="false">
      <ng-template let-item>
        <div class="carousel__content">
          <div class="content__image">
            <img [src]="item.image" [alt]="item.text" />

            <div class="content__text" [ngStyle]="getQuoteWrapperStyle(item)">
              <p [ngStyle]="getQuoteStyle(item)">{{ item.text }}</p>
              <p *ngIf="item.signature" [ngStyle]="getQuoteStyle(item)">{{ item.signature }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </shared-carousel>
  </div>
</div>