import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { Menu } from '@core/models';

// TEMP
import { linksMock, menuMock } from '@test/mocks/menu.mock';

const menu: Menu[] = menuMock;
const links: Menu[] = linksMock;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly apiLayoutUrl: string = `${ this.configService.apiUrl }/layout`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public loadMenu(): Observable<Menu[]> {
    // return this.httpService
    //   .perform<null, Menu[]>(HttpMethod.get, `${this.apiLayoutUrl}/menu`);

    return of(menu.filter((m: Menu) => !m.disabled));
  }

  public loadLinks(): Observable<Menu[]> {
    // return this.httpService
    //   .perform<null, Menu[]>(HttpMethod.get, `${this.apiLayoutUrl}/links`);

    return of(links.filter((m: Menu) => !m.disabled));
  }
}
