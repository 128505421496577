import { Component, Input } from '@angular/core';
import { ContentComponent } from '@shared/components/contents/content.component';
import { Style, Widget, WidgetContent } from '@core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'shared-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultPadding: string = '0.5rem';
  private defaultMargin: string = '0';
  private defaultBackgroundColor: string = 'transparent';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { text: string, path: string } {
    return this.contentWidget?.content as { text: string, path: string };
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get style(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      backgroundColor: this.contentStyle.backgroundColor || this.defaultBackgroundColor,
    };
  }

  constructor(
    private readonly router: Router,
  ) {
  }

  public onClick(): void {
    this.router.navigate(this.content.path.split('/'));
  }
}
