<div class="expertise-loader">
  <div class="expertise-loader__banner">
    <shared-skeleton-loader count="1" appearance="block"></shared-skeleton-loader>
  </div>

  <div class="expertise-loader__multi-blocks">
    <shared-skeleton-loader count="2" appearance="block" height="250px"></shared-skeleton-loader>
  </div>

  <div class="expertise-loader__multi-blocks">
    <shared-skeleton-loader count="3" appearance="block" height="300px"></shared-skeleton-loader>
  </div>

  <div class="expertise-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="100px"></shared-skeleton-loader>
  </div>
</div>