import { createAction, props } from '@ngrx/store';

import { Form } from '@core/models';

export const SEND: string = '[Contact] Send';
export const SEND_SUCCESS: string = '[Contact] Send success';
export const SEND_FAIL: string = '[Contact] Send fail';
export const RESET_FORM: string = '[Contact] Reset form';

export const send: any = createAction(
  SEND,
  props<Form>(),
);

export const sendSuccess: any = createAction(
  SEND_SUCCESS,
);

export const sendFail: any = createAction(
  SEND_FAIL,
  props<{ error: any }>(),
);

export const resetForm: any = createAction(
  RESET_FORM,
);
