import { WidgetEnum } from './enums/widget.enum';
import { WidgetContent } from './interfaces/widget-content.interface';

export class Widget {
  constructor(
    public id: string,
    public sort: number,
    public type: WidgetEnum,
    public content: WidgetContent,
  ) {}
}
