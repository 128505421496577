import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as LayoutActions from '@core/store/actions';
import { Menu } from '@core/models';

export interface MenuState {
  loading: boolean;
  loaded: boolean;
  error: string;
  data: Menu[];
}

export interface LayoutState {
  menu: MenuState;
  links: MenuState;
}

export const initialLayoutState: LayoutState = {
  menu: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  links: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

const reducer: ActionReducer<LayoutState, Action> = createReducer(
  initialLayoutState,
  on(LayoutActions.loadMenu, (state: LayoutState) => ({
    ...state,
    menu: {
      ...state.menu,
      loading: true,
      loaded: false,
      error: null,
    },
  })),
  on(LayoutActions.loadMenuSuccess, (state: LayoutState, { menu }: { menu: Menu[] }) => ({
    ...state,
    menu: {
      ...state.menu,
      loading: false,
      loaded: true,
      error: null,
      data: menu,
    },
  })),
  on(LayoutActions.loadMenuFail, (state: LayoutState, { error }: { error: string }) => ({
    ...state,
    menu: {
      ...state.menu,
      loading: false,
      loaded: false,
      error,
    },
  })),

  on(LayoutActions.loadLinks, (state: LayoutState) => ({
    ...state,
    links: {
      ...state.links,
      loading: true,
      loaded: false,
      error: null,
    },
  })),
  on(LayoutActions.loadLinksSuccess, (state: LayoutState, { links }: { links: Menu[] }) => ({
    ...state,
    links: {
      ...state.links,
      loading: false,
      loaded: true,
      error: null,
      data: links,
    },
  })),
  on(LayoutActions.loadLinksFail, (state: LayoutState, { error }: { error: string }) => ({
    ...state,
    links: {
      ...state.links,
      loading: false,
      loaded: false,
      error,
    },
  })),
);

export function layoutReducer(state: LayoutState | undefined, action: Action): LayoutState {
  return reducer(state, action);
}
