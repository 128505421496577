import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import {
  appComponents,
  appDialogs,
  appDirectives,
  appGuards,
  appModules,
  appPipes,
  appServices,
  materialModules,
} from './index';

@NgModule({
  imports: [
    RouterModule,
    TranslateModule.forChild(),
    ...appModules,
    ...materialModules,
  ],
  declarations: [
    ...appComponents,
    ...appPipes,
    ...appDirectives,
  ],
  providers: [
    ...appServices,
    ...appGuards,
  ],
  exports: [
    TranslateModule,
    ...materialModules,
    ...appComponents,
    ...appPipes,
    ...appDirectives,
  ],
  entryComponents: [
    ...appDialogs,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...appServices,
        ...appGuards,
      ],
    };
  }
}
