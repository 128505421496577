import { Component, ComponentRef, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { ContentDirective } from '@shared/directives';
import { BlogComponent } from '@shared/components/pages';
import { RealPageComponent } from '@shared/components/containers/real-page.component';
import { PageEnum } from '@core/models/enums/page.enum';

@Component({
  selector: 'shared-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @ViewChild(ContentDirective, { static: true }) public itemContent!: ContentDirective;

  @Input() public pageId: PageEnum;

  public ngOnInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 200);
  }

  private loadComponent(): void {
    const page: { data: any, component: Type<RealPageComponent> } = this.pageMapping(this.pageId);
    const viewContainerRef: ViewContainerRef = this.itemContent?.viewContainerRef;

    if (viewContainerRef) {
      viewContainerRef.clear();

      const componentRef: ComponentRef<RealPageComponent> = viewContainerRef.createComponent<RealPageComponent>(page.component);
      componentRef.instance.data = page.data;
    }
  }

  private pageMapping(pageId: PageEnum): { data: any, component: Type<RealPageComponent> } {
    const mapping: { [key in PageEnum]: { data: any, component: Type<RealPageComponent> } } = {
      [PageEnum.BLOG]: { data: {}, component: BlogComponent },
      [PageEnum.ARTICLE]: { data: {}, component: BlogComponent },
    };

    return mapping[pageId];
  }
}
