import { SkeletonLoaderModule } from './skeleton-loader/skeleton-loader.module';
import { ColorsEnum } from '@core/models/enums/colors.enum';

export const sharedModules: any[] = [
  SkeletonLoaderModule.forRoot({
    animation: 'pulse',
    theme: {
      'background-color': ColorsEnum.GRAY,
    },
  }),
];

export * from './skeleton-loader/skeleton-loader.module';
