<div class="about-loader">
  <div class="about-loader__profile">
    <shared-skeleton-loader count="1" appearance="circle"></shared-skeleton-loader>
  </div>

  <div class="about-loader__separator">
    <shared-skeleton-loader count="1" appearance="separator"></shared-skeleton-loader>
  </div>

  <div class="about-loader__name">
    <shared-skeleton-loader count="1" appearance="line"></shared-skeleton-loader>
  </div>

  <div class="about-loader__text">
    <shared-skeleton-loader count="1" appearance="line"></shared-skeleton-loader>
  </div>

  <div class="about-loader__text-block">
    <shared-skeleton-loader count="1" appearance="block" height="250px"></shared-skeleton-loader>
  </div>

  <div class="about-loader__text-block">
    <shared-skeleton-loader count="1" appearance="block" height="100px"></shared-skeleton-loader>
  </div>

  <div class="about-loader__text-block">
    <shared-skeleton-loader count="1" appearance="block" height="200px"></shared-skeleton-loader>
  </div>

  <div class="about-loader__text-block">
    <shared-skeleton-loader count="1" appearance="block" height="150px"></shared-skeleton-loader>
  </div>
</div>