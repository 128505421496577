<div class="layout-menu-mobile">
  <button class="layout-menu-mobile__close" mat-icon-button (click)="closeMenu()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="layout-menu-mobile__menu">
    <ng-container *ngFor="let tab of menu; trackBy:trackById">
      <ng-container *ngIf="tab.allowChildren; else link">
        <mat-expansion-panel [expanded]="isOpen(tab.id) || isActive(tab.id)"
          (opened)="toggleSub(tab.id)" (closed)="toggleSub(tab.id)">
          <mat-expansion-panel-header [ngClass]="{ active: isActive(tab.id) }">
            <mat-panel-title class="menu__tab with-sub">
              {{ tab.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="menu__sub" *ngIf="openedTab === tab.id">
            <a class="menu__tab" *ngFor="let subtab of tab.children; trackBy:trackById" routerLinkActive="active" [routerLink]="subtab.path">
              {{ subtab.name }}
            </a>
          </div>
        </mat-expansion-panel>
      </ng-container>

      <ng-template #link>
        <a class="menu__tab" [ngClass]="{ active: isActive(tab.id) }" [routerLink]="tab.path">
          {{ tab.name }}
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>
