<div class="big-page-loader">
  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="100px"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="200px"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="200px"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="100px"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="200px"></shared-skeleton-loader>
  </div>

  <div class="big-page-loader__banner">
    <shared-skeleton-loader count="1" appearance="block" height="100px"></shared-skeleton-loader>
  </div>
</div>
