import { ApplicationRef, ComponentRef, NgModuleRef } from '@angular/core';
import { createNewHosts } from '@angularclass/hmr';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hmrBootstrap = (module: any, bootstrap: () => Promise<NgModuleRef<any>>): void => {
  let ngModule: NgModuleRef<any>;

  module.hot.accept();
  bootstrap().then((mod) => (ngModule = mod));

  module.hot.dispose(() => {
    const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
    const elements: ComponentRef<any>[] = appRef.components.map((c) => c.location.nativeElement);
    const makeVisible: () => void = createNewHosts(elements);

    ngModule.destroy();
    makeVisible();
  });
};
