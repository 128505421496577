import { Component, Input } from '@angular/core';
import { ContactInfo, Menu, Widget, WidgetContent, WidgetEnum } from '@core/models';

@Component({
  selector: 'shared-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() public menu: Menu[];
  @Input() public links: Menu[];

  public widget: Widget = new Widget('123', 1, WidgetEnum.CONTACT, {
    style: { width: '100%', padding: '0', maxWidth: '850px' },
    content: {
      fields: new ContactInfo(),
      displayedFields: [
        'name',
        'company',
        'phone',
        'email',
        'address',
        'facebook',
        'linkedIn',
      ],
    },
  });

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { fields: ContactInfo, displayedFields: string[] } {
    return this.contentWidget?.content as { fields: ContactInfo, displayedFields: string[] };
  }

  public get contact(): ContactInfo {
    return this.content?.fields;
  }

  public displayField(field: string | string[]): boolean {
    if (field instanceof Array) {
      return field.some((f: string) => this.displayField(f));
    }

    return this.content.displayedFields.includes(field) && !!this.contact[field];
  }

  public goTo(link: string): void {
    window.open(link, '_blank');
  }

  public trackById(idx: number, item: Menu): string {
    return item.id;
  }
}
