import { Moment } from 'moment';

import { Menu } from './menu.model';
import { Style } from '@core/models/interfaces/style.interface';

export class Testimony {
  constructor(
    public id: string,
    public menu: Menu,
    public text: string,
    public signature: string,
    public button: string,
    public createdAt: Moment,
    public style: Style = null,
    public disabled: boolean = false,
  ) {}
}
