import { Component, ComponentRef, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { Widget, WidgetEnum } from '@core/models';

import { ContentDirective } from '@shared/directives';
import { ContentComponent } from '@shared/components/contents/content.component';
import { TextComponent } from '@shared/components/contents/text/text.component';
import { SeparatorComponent } from '@shared/components/contents/separator/separator.component';
import { QuotesComponent } from '@shared/components/contents/quotes/quotes.component';
import { ButtonComponent } from '@shared/components/contents/button/button.component';
import { ArticlesComponent } from '@shared/components/contents/articles/articles.component';
import { TestimoniesComponent } from '@shared/components/contents/testimonies/testimonies.component';
import { ImageComponent } from '@shared/components/contents/image/image.component';
import { CircleBlockComponent } from '@shared/components/contents/circle-block/circle-block.component';
import { ContactComponent } from '@shared/components/contents/contact/contact.component';
import { ProfilePictureComponent } from '@shared/components/contents/profile-picture/profile-picture.component';
import { ContactFormContentComponent } from '@shared/components/contents/contact-form/contact-form.component';
import { CardComponent } from '@shared/components/contents/card/card.component';

@Component({
  selector: 'shared-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
  @ViewChild(ContentDirective, { static: true }) public itemContent!: ContentDirective;

  @Input() public widget: Widget;

  public ngOnInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 200);
  }

  private loadComponent(): void {
    const item: { data: Widget, component: Type<ContentComponent> } = this.typeMapping(this.widget.type);
    const viewContainerRef: ViewContainerRef = this.itemContent?.viewContainerRef;

    if (viewContainerRef) {
      viewContainerRef.clear();

      const componentRef: ComponentRef<ContentComponent> = viewContainerRef.createComponent<ContentComponent>(item.component);
      componentRef.instance.widget = item.data;
    }
  }

  private typeMapping(type: WidgetEnum): { data: Widget, component: Type<ContentComponent> } {
    const mapping: { [key in WidgetEnum]: { data: Widget, component: Type<ContentComponent> } } = {
      [WidgetEnum.TEXT]: { data: this.widget, component: TextComponent },
      [WidgetEnum.IMAGE]: { data: this.widget, component: ImageComponent },
      [WidgetEnum.BUTTON]: { data: this.widget, component: ButtonComponent },
      [WidgetEnum.SEPARATOR]: { data: this.widget, component: SeparatorComponent },
      [WidgetEnum.CIRCLE_BLOCK]: { data: this.widget, component: CircleBlockComponent },
      [WidgetEnum.QUOTES]: { data: this.widget, component: QuotesComponent },
      [WidgetEnum.TESTIMONIES]: { data: this.widget, component: TestimoniesComponent },
      [WidgetEnum.CONTACT]: { data: this.widget, component: ContactComponent },
      [WidgetEnum.CONTACT_FORM]: { data: this.widget, component: ContactFormContentComponent },
      [WidgetEnum.ARTICLES]: { data: this.widget, component: ArticlesComponent },
      [WidgetEnum.PROFILE_PICTURE]: { data: this.widget, component: ProfilePictureComponent },
      [WidgetEnum.CARD]: { data: this.widget, component: CardComponent },
    };

    return mapping[type];
  }
}
