import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { HttpService } from '@core/services/http/http.service';
// import { HttpMethod } from '@core/services/http/http-method';
import { ConfigService } from '@shared/services';
import { Page } from '@core/models';

// TEMP
import { findPageByPath } from '@test/mocks/pages/pages.mock';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private readonly apiLayoutUrl: string = `${ this.configService.apiUrl }/page`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
    private readonly router: Router,
  ) {}

  public loadPage(path: string): Observable<Page> {
    // return this.httpService
    //   .perform<null, Menu[]>(HttpMethod.get, `${this.apiLayoutUrl}/${id}`);
    return of(findPageByPath(path, this.router)).pipe(delay(1000));
  }
}
