<div class="contact-form">
  <div class="contact-form__success" *ngIf="isSent; else form">
    <div class="success__title">
      {{ 'CONTACT-FORM.SUCCESS.TITLE' | translate }}
    </div>

    <div class="success__subtitle">
      {{ 'CONTACT-FORM.SUCCESS.SUBTITLE' | translate }}
    </div>

    <div *ngIf="displayCancelButton" class="success__actions">
      <button mat-raised-button color="primary" (click)="onCancel()">
        {{ 'CONTACT-FORM.ACTIONS.CANCEL' | translate }}
      </button>
    </div>
  </div>

  <ng-template #form>
    <div *ngIf="displayTitle" class="contact-form__header">
      <div class="header__title">
        {{ 'CONTACT-FORM.TITLE' | translate }}
      </div>

      <div class="header__subtitle">
        {{ 'CONTACT-FORM.SUBTITLE' | translate }}

        <span class="header__subtitle--accent">
          {{ email }}<span class="hackermann">@</span>{{ domain }}
        </span>
      </div>
    </div>

    <div class="contact-form__content">
      <form class="content__form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="form__subject">
            <mat-label [ngClass]="requiredClass">{{ 'CONTACT-FORM.SUBJECT.LABEL' | translate }}</mat-label>

            <mat-radio-group class="form__input" formControlName="subject">
              <mat-radio-button *ngFor="let subject of subjects; trackBy:trackBySubject" [value]="subject">
                {{ subject }}
              </mat-radio-button>
            </mat-radio-group>

          <mat-error *ngIf="submitted && formControls.subject.errors">
              {{ 'CONTACT-FORM.SUBJECT.ERROR' | translate }}
            </mat-error>
        </div>

        <div class="form__body">
          <mat-form-field class="form__input" appearance="outline">
            <mat-label>{{ 'CONTACT-FORM.BODY.LABEL' | translate }}</mat-label>
            <textarea formControlName="body" type="text" class="input__value" rows="4"
              [readonly]="isLocked" matInput></textarea>

            <mat-error *ngIf="formControls.body.errors">
              {{ 'CONTACT-FORM.BODY.ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__name">
          <mat-form-field class="form__input" appearance="outline">
            <mat-label>{{ 'CONTACT-FORM.NAME.LABEL' | translate }}</mat-label>
            <input formControlName="name" type="text" class="input__value"
              autocomplete="name" name="name"
              [readonly]="isLocked" matInput>

            <mat-error *ngIf="formControls.name.errors">
              {{ 'CONTACT-FORM.NAME.ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__email">
          <mat-form-field class="form__input" appearance="outline">
            <mat-label>{{ 'CONTACT-FORM.EMAIL.LABEL' | translate }}</mat-label>
            <input formControlName="email" type="email" class="input__value"
              autocomplete="email" name="email"
              [readonly]="isLocked" matInput>

            <mat-error *ngIf="formControls.email.errors">
              {{ 'CONTACT-FORM.EMAIL.ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__phone">
          <mat-form-field class="form__input" appearance="outline">
            <mat-label>{{ 'CONTACT-FORM.PHONE.LABEL' | translate }}</mat-label>
            <input formControlName="phone" type="text" class="input__value"
              autocomplete="tel" name="tel"
              [readonly]="isLocked" matInput>

            <mat-error *ngIf="formControls.phone.errors">
              {{ 'CONTACT-FORM.PHONE.ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form__gdrp">
          <mat-label [ngClass]="requiredClass">{{ 'CONTACT-FORM.GDRP.LABEL' | translate }}</mat-label>
          <mat-checkbox formControlName="gdrp" color="warn">
            {{ 'CONTACT-FORM.GDRP.HELP' | translate }}
          </mat-checkbox>

          <mat-error *ngIf="submitted && formControls.gdrp.errors">
            {{ 'CONTACT-FORM.GDRP.ERROR' | translate }}
          </mat-error>
        </div>

        <div class="contact-form__error" *ngIf="hasError">
          {{ 'CONTACT-FORM.ERROR' | translate }}
        </div>

        <div class="form__actions" [ngClass]="{ 'form__actions--right': displayCancelButton }">
          <button *ngIf="displayCancelButton" mat-button type="button" [disabled]="isSending" (click)="onCancel()">
            {{ 'CONTACT-FORM.ACTIONS.CANCEL' | translate }}
          </button>

          <button mat-raised-button color="primary" [disabled]="isSending">
            <mat-spinner *ngIf="isSending; else text" [diameter]="25"></mat-spinner>

            <ng-template #text>
              {{ 'CONTACT-FORM.ACTIONS.SUBMIT' | translate }}
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
