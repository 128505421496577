import { Routes } from '@angular/router';

import { DynamicComponent } from '@app/components';
import { PageResolver } from '@shared/resolvers/page.resolver';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: DynamicComponent,
    // canActivate: [
    //   CanAccessGuard,
    // ],
    resolve: {
      page: PageResolver,
    },
  },
  // {
  //   path: 'coming-soon',
  //   component: ComingSoonComponent,
  // },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('@admin/admin.module').then(m => m.AdminModule),
  //   canActivate: [],
  // },
  {
    path: ':page',
    component: DynamicComponent,
    resolve: {
      page: PageResolver,
    },
  },
  {
    path: ':page/:slug',
    component: DynamicComponent,
    resolve: {
      page: PageResolver,
    },
  },
  // TODO
  // {
  //   path: 'expertise/:slug',
  //   component: DynamicComponent,
  // },
  // TODO
  // {
  //   path: 'about-me',
  //   component: AboutComponent,
  // },
  // TODO
  // {
  //   path: 'blog',
  //   children: [
  //     { path: '', component: BlogComponent },
  //     { path: ':id', component: ArticleComponent },
  //   ]
  // },
  // TODO
  // {
  //   path: 'contact',
  //   component: ContactComponent,
  // },
  // TODO
  // {
  //   path: '**',
  //   component: Error404Component,
  // },
];
