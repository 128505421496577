import { Component, Input } from '@angular/core';

import { Style, Widget, WidgetContent } from '@core/models';

import { ContentComponent } from '@shared/components/contents/content.component';

@Component({
  selector: 'shared-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements ContentComponent {
  @Input() public widget: Widget;

  private defaultAlign: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultJustify: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  private defaultPadding: string = '0';
  private defaultMargin: string = '0';
  private defaultObjectFit: string = 'cover';
  private defaultObjectPosition: string = 'center';
  private defaultWidth: string = '100%';
  private defaultHeight: string = 'auto';

  public get contentWidget(): WidgetContent {
    return this.widget?.content;
  }

  public get content(): { text: string, path: string } {
    return this.contentWidget?.content as { text: string, path: string };
  }

  public get contentStyle(): Style {
    return this.contentWidget?.style;
  }

  public get styleBlock(): { [key: string]: string } {
    return {
      alignItems: this.contentStyle.align || this.defaultAlign,
      justifyContent: this.contentStyle.justify || this.defaultJustify,
      padding: this.contentStyle.padding || this.defaultPadding,
      margin: this.contentStyle.margin || this.defaultMargin,
      width: this.contentStyle.width || this.defaultWidth,
      height: this.contentStyle.height || this.defaultHeight,
      maxWidth: this.contentStyle.maxWidth || 'none',
      maxHeight: this.contentStyle.maxHeight || 'none',
    };
  }

  public get styleImage(): { [key: string]: string } {
    return {
      objectFit: this.contentStyle.objectFit || this.defaultObjectFit,
      objectPosition: this.contentStyle.objectPosition || this.defaultObjectPosition,
    };
  }
}
