import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { LoginComponent } from './login/login.component';
import { DynamicComponent } from './dynamic/dynamic.component';
import { LayoutComponent } from './layout/layout.component';

export const appComponents: any[] = [
  ComingSoonComponent,
  ContactFormComponent,
  LoginComponent,
  DynamicComponent,
  LayoutComponent,
];

export const appDialog: any[] = [
  ContactFormComponent,
];

export * from './coming-soon/coming-soon.component';
export * from './contact-form/contact-form.component';
export * from './login/login.component';
export * from './dynamic/dynamic.component';
export * from './layout/layout.component';
