import { Menu } from './menu.model';

export class FormSubject {
  constructor(
    public id: string,
    public title: string,
    public menu?: Menu,
    public disabled: boolean = false,
  ) {}
}
