<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" class="clesdefa-logo" [class.clesdefa-logo--white]="white">
  <g>
    <g id="g858_5_" transform="translate(12.215)">
      <path id="path855_8_" fill="#E9511F" fill-opacity="0.75" d="M85.1,11.8C85,13,84.1,13.9,83,13.9c-1,0-1.9-1-1.9-2.3v-0.1
			c0.1-1.1,0.9-2.1,2.1-2.1C84.3,9.5,85.2,10.5,85.1,11.8C85.1,11.7,85.1,11.7,85.1,11.8z"/>
      <path id="path857_8_" fill="#E9511F" fill-opacity="0.75" d="M83.8,22.4c-0.1,1.1-0.9,2.1-2.1,2.1c-1,0-1.9-1-1.9-2.3v-0.1
			C80,21,80.8,20,81.9,20C83.1,20.1,83.9,21.1,83.8,22.4C83.8,22.3,83.8,22.3,83.8,22.4z"/>
    </g>

    <linearGradient id="path861_1_" gradientUnits="userSpaceOnUse" x1="116.0956" y1="135.6234" x2="107.4587"
      y2="117.6853" gradientTransform="matrix(0.5819 0.1015 0.1015 -0.5819 -8.2515 77.717)">
      <stop offset="0.1832" style="stop-color:#E95D31"/>
      <stop offset="0.6114" style="stop-color:#D43849"/>
      <stop offset="1" style="stop-color:#232248"/>
    </linearGradient>
    <path id="path861_8_" fill="url(#path861_1_)" fill-opacity="0.75" d="M75.1,15.8c-0.2,3.3-2.9,5.8-5.9,5.6c-3-0.2-5.2-3.1-5-6.4
		c0.2-3.3,2.9-5.8,5.9-5.6C73.1,9.6,75.4,12.4,75.1,15.8z"/>

    <linearGradient id="path864_1_" gradientUnits="userSpaceOnUse" x1="147.9558" y1="177.9083" x2="117.6284"
      y2="114.9203" gradientTransform="matrix(0.5819 0.1015 0.1015 -0.5819 -8.2515 77.717)">
      <stop offset="0.1832" style="stop-color:#E95D31"/>
      <stop offset="0.6114" style="stop-color:#D43849"/>
      <stop offset="1" style="stop-color:#232248"/>
    </linearGradient>
    <path id="path864_5_" fill="url(#path864_1_)" d="M64.2,16.2C62.4,6.3,69,0.4,77,0.8c8.1,0.4,12.7,8.7,12.3,16.1
		c-0.3,7.4-3.7,13.2-10.3,20c-5.4,5.7-15.3,9.8-15.9,9c-0.7-0.7,7.6-4.1,13.1-10.1c3.4-3.7,7.3-12.1,7.5-18.3
		C83.7,9.2,78.1,4,75.8,3.4c-1.6-0.5-3.1-0.2-4.7,0.5C69.9,4.4,69,5.8,69,6.5c-0.2,1.6,1.6,2.7,2.5,3.1C75,10.9,68.9,21.1,64.2,16.2
		L64.2,16.2z"/>
  </g>
  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="6.3105" y1="86.1598" x2="147.5321" y2="68.3247"
    gradientTransform="matrix(0.5907 0 0 -0.5907 0.4093 120.1533)">
    <stop offset="0.1832" style="stop-color:#E95D31"/>
    <stop offset="0.6114" style="stop-color:#D43849"/>
    <stop offset="1" style="stop-color:#232248"/>
  </linearGradient>
  <path fill="url(#SVGID_1_)" d="M92.5,111.5c0,0,2.4-17.6-16-40.3c0,0-11.3-16.8-10.4-20.4c0,0-6.6,4.7,0,17.6c0,0,5.1,12.8-0.6,16.1
	c-5.7,3.3-24-12.7-21-20.4c3-7.8,11.1-4.3,13.2-17.2c0,0-19.6,7.6-21.4,16.8c0,0,0.8,15.9,6,19.5c0,0-17.6-10.5-16.7-28.2
	s-6.2-14.7-8.3-14.8c0,0,0.7,8.3,0.9,13.8c0,0-0.3,13.8,5,23.2c0,0-7.2-15.4-5.7-21.1c0,0-2.9,1-3.1,4.5c-0.2,5.4,2.2,15,8.6,23.7
	c10.5,14.4,31.7,17.9,44.4,17.8C67.2,102,89.9,102.6,92.5,111.5z"/>
  <g>
    <path fill="#E9511F"
      d="M26.6,90.2c4.8,4.4-12.7-2.5-19.2-2.2c3.4-2.7,7.7-4.3,12.1-4.6C19.5,83.5,25.2,89,26.6,90.2z"/>
  </g>
  <g>
    <path fill="#DD2540" d="M6.4,144.6c5.5-4.8,11.9-8.7,18.3-12.3c6.4-3.5,13.3-6.4,20.3-8.8c7-2.5,14.2-4.2,21.5-5.5
		c7.4-1.2,14.8-1.8,22.3-1.8h2.8c0.9,0,1.9,0,2.8,0.1l5.6,0.3c1.9,0.2,3.7,0.4,5.5,0.6c1.9,0.2,3.6,0.5,5.5,0.8
		c7.3,1.2,14.6,3.2,21.5,5.6c13.9,5,27,12.1,38.8,20.8c-12.9-7-26.2-12.8-40-17c-1.7-0.6-3.4-0.9-5.2-1.4c-1.8-0.4-3.4-0.9-5.2-1.4
		l-5.3-1c-1.8-0.4-3.5-0.5-5.3-0.8l-2.7-0.4c-0.9-0.1-1.8-0.2-2.7-0.3c-1.8-0.2-3.5-0.4-5.3-0.5l-5.4-0.3c-1.8-0.1-3.5,0-5.4-0.1
		c-14.4-0.1-28.7,2-42.5,5.9c-7,2-13.7,4.4-20.4,7.3C19,137.5,12.6,140.8,6.4,144.6z"/>
  </g>
  <g>
    <text transform="matrix(1 0 0 1 9.8805 174.0177)" fill="#DD2540" font-family="'CaviarDreams'" font-size="31.6075">
      Clés de Fa
    </text>
  </g>
</svg>
